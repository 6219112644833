import { Account, TonProofItemReplySuccess, Wallet, WalletInfoWithOpenMethod } from "@tonconnect/ui-react";

export class Proofs{
    
    static async verifyProof(wallet: Wallet | (Wallet & WalletInfoWithOpenMethod) | null){
      const w = wallet;        
      if (w?.connectItems?.tonProof && 'proof' in w.connectItems.tonProof) {          
          const session = await Proofs.checkProof(w?.connectItems?.tonProof?.proof, w.account);            
          if(session){              
              return session;
          }
      }      
      return false;
    }
    static async checkProof(proof: TonProofItemReplySuccess['proof'], account: Account): Promise<string | null> {
        try {
          const reqBody = {
            address: account.address,
            network: account.chain,
            public_key: account.publicKey,
            proof: {
              ...proof,
              state_init: account.walletStateInit,
            },
          };
          const request = await fetch(`${process.env.REACT_APP_API_URL}/ton/check_proof`, {
            method: 'POST',
            body: JSON.stringify(reqBody),
            headers: {
              'Content-Type': 'application/json'
            },
          });                  
          console.log(request.status);
          if(!request.ok){
            return null;
          }
          const response = await request.json();          
          console.log(response);
          return response.data.session;          
        } catch (e) {
          console.log('checkProof error:', e);
          return null;
        }
      }
}