import { useContext } from 'react';
import { BattleHeroContext, useBattleHero } from '../../Hook/useBattleHero';
import yellow from './yellow.png';
import { AccountLinkedContext } from '../../Context/AccountLinkedContext';
import { formatNumber } from '../../utils/formatNumber';
import { WalletContext } from '../../Context/WalletContext';
import { ItemImage } from '../ItemImage/ItemImage';
export function TonCounter(){
    // const {increaseThund, decreaseThund} = useContext(BattleHeroContext);        
    const {balance} = useContext(WalletContext);
    // if(!linkedUser?.balance) return <></>
    return <div className='flex flex-row items-center bg-slate-800 pt-2 pb-2 pr-2 pl-4 rounded-lg relative z-50'>
        <span>{formatNumber(balance)}</span> <div className='ml-2'><ItemImage id={"ton"} width={14}/></div>
        {/* {decreaseThund < 0 && <div className='animate-bounce absolute rounded -bottom-8 left-0 w-full bg-slate-800'>
            {decreaseThund}
        </div>} */}
        </div>
}