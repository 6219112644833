import { useTonConnectUI } from "@tonconnect/ui-react";
import React, { useContext, useEffect, useState } from "react";
import ContainerLayout from "../Components/Layouts/ContainerLayout";
import { AccountLinkedContext, AccountLinkedProvider } from "./AccountLinkedContext";
import { WalletContext, WalletProvider } from "./WalletContext";
import { isSessionValid } from "../utils/api";
import { useProfile } from "../Hook/useProfile";

export interface ProfileContextState {
    fetchProfile: () => Promise<void>;
    offChainBathBalance: number;
    offChainPeBalance: number;
    gemsBalance: number;
    email: string;
    nickname: string;
    selectedAvatarId: number;
    cryptoRouletteTickets: number;
}
export const ProfileContext = React.createContext<ProfileContextState>({} as ProfileContextState);

export const ProfileProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const state = useProfile();
    return <ProfileContext.Provider value={state}>            
                        {children}
         </ProfileContext.Provider>;
}
