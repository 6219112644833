
import { HiChartPie } from "react-icons/hi2";
import { Spinner } from "flowbite-react";
import box from '../Components/Boxes/box.png';
import yellow from '../Components/Boxes/yellow.png';
import tsk from '../Components/Boxes/tsk.png';
import { useTasks } from "../Hook/useTasks";
import { TaskItem } from "./Tasks/TaskItem";
import { GrCompliance } from "react-icons/gr";
import { MdOutlineIncompleteCircle } from "react-icons/md";
import { useContext, useState } from "react";
import { TaskItemProps } from "./Tasks/TaskItemProps";
import { TaskMeunItem } from "./Tasks/TaskMeunItem";
import { TaskList } from "./Tasks/TaskList";
import { TasksContext } from "../Context/TasksContext";
import { CSSTransition, TransitionGroup } from 'react-transition-group';


export interface TaskReward{
    amount: number;
    id: number;
}
export type TaskTypes = 'completeds' | 'uncompleteds';

export function Tasks(){
    const {tasks, loading} = useContext(TasksContext);       
    const [taskType, setTaskType] = useState<TaskTypes>('uncompleteds');

    const toItemProps = (e: any) => {                   
        return  {
            id: e.task.id,
            name: e.task.name, 
            description: e.task.description,
            timeToReset: e.progress.timeToReset,
            claimed: e.progress.claimed,
            largeDescription: e.task.largeDescription,
            resetable: e.task.resetable,
            progress: (e.progress.value / e.task.neededValue) * 100, 
            disabled: false, 
            link: e.task.link, 
            icon: HiChartPie, 
            rewards: e.task.rewards
        };
    }
    
    const completedTasks: TaskItemProps[]   = tasks?.filter((e: any) => e.progress.claimed || e.progress.value >= e.task.neededValue).map(toItemProps);
    tasks?.sort((a, b) => a.progress.claimed - b.progress.claimed);
    const uncompletedTasks: TaskItemProps[] = tasks?.filter((e: any) => !e.progress.claimed && e.progress.value < e.task.neededValue).map(toItemProps);
    const selectedTasks = taskType == 'uncompleteds' ? uncompletedTasks : completedTasks;
    const unclaimeds = tasks?.filter((e: any) => !e.progress.claimed && e.progress.value >= e.task.neededValue).map(toItemProps);

    const handleSelectTaskType = (type: TaskTypes) => {
        setTaskType(type);
    }

    if(loading && tasks.length == 0){
        return <div className="w-full h-full justify-center text-white p-4 flex flex-col items-center">        
            <Spinner size={"xl"}></Spinner>
        </div>
    }

    return <div className="h-full w-full flex flex-col">      
        <div className="flex-1 sticky top-0 left-0 z-50 bg-[#123553] text-white text-left ml-2 pb-2">
            Complete tasks to earn TSK and compete for monthly airdrops. The first 1000 users each month will receive a number of TG boxes to get NFT's.
        </div>  
        {/* <div className="flex-1 w-full flex flex-col p-2 gap-2"> */}
            <TransitionGroup className="task-list flex-1 w-full flex flex-col p-2 gap-2">
            {selectedTasks.map((task) => (
                <CSSTransition key={task.id} timeout={200} classNames="fade">
                <TaskList tasks={[task]} />
                </CSSTransition>
            ))}
            </TransitionGroup>
        {/* </div> */}
        <nav className="sticky text-white bottom-0 left-0 z-50 bg-slate-800 shadow-t-md flex flex-row justify-evenly">
            <TaskMeunItem taskType={'uncompleteds'} selected={taskType == 'uncompleteds'} icon={<MdOutlineIncompleteCircle/>} text={'Pendings'} onClick={handleSelectTaskType}></TaskMeunItem>
                <div className="w-[4px] bg-slate-700 h-auto"></div>                             
            <TaskMeunItem indicator={unclaimeds.length > 0} taskType={'completeds'} selected={taskType == 'completeds'} icon={<GrCompliance/>} text={'Completeds'} onClick={handleSelectTaskType}></TaskMeunItem>
        </nav>
    </div>
}


