import React, { useContext, useMemo, useState } from "react";
import { useTransactions } from "../Hook/useTransactions";
import { IoCloseCircleOutline } from "react-icons/io5";
import { TransactionItem } from "../Components/TransactionItem";
import { pollTransaction } from "../utils/api";

export interface TransactionsContextState {
    pollTransaction: (signature: string, payload: any, extraData?: any) => Promise<any>;
    openTransactions: () => void;
    transactions: any[];
}
export const TransactionsContext = React.createContext<TransactionsContextState>({} as TransactionsContextState);

export const TransactionsProvider: React.FC<{ children: React.ReactNode }> = (
    { children },
) => {
    const [showTransactions, setShowTransactions] = useState<boolean>(false);
    const {transactions} = useTransactions();    

    const openTransactions = () => {
        setShowTransactions(true);
    }
    const memoizedTransactions = useMemo(() => transactions, [transactions]);

    const _pollTransaction = async (signature: string, payload: any, extraData?: any) => {
        await pollTransaction(signature, payload, extraData);
    }

    return (<TransactionsContext.Provider value={{pollTransaction: _pollTransaction, openTransactions, transactions: memoizedTransactions}}>
            {children}
            {showTransactions && <div className="fixed top-0 left-0 bg-black/80 w-full h-full">                    
                    <div className="transition-transform absolute rounded bottom-0 left-0 w-full h-[200px] bg-white overflow-y-auto">
                        <div className="w-full flex flex-row justify-between items-center p-2">
                            <span>Transactions</span>
                            <IoCloseCircleOutline onClick={() => setShowTransactions(false)} size={22}/>
                        </div>
                        {memoizedTransactions.map((e: any) => {
                            return <TransactionItem transaction={e}/>
                        })}
                    </div>
                </div>}
        </TransactionsContext.Provider>
    )
}

export const usePollTransactions = () => useContext(TransactionsContext).pollTransaction;
