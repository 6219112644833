import { useContext, useEffect, useState, useRef } from "react";
import { fetchWrapper } from "../utils/fetchWrapper";
import { fetchTransactions, pollTransaction } from "../utils/api";
import { AuthenticationContext } from "../Context/AuthenticationContext";
import { useToast } from "../Context/ToastContext";
import { AccountLinkedContext } from "../Context/AccountLinkedContext";
import { ProfileContext } from "../Context/ProfileContext";

export function useTransactions() {
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const prevTransactions = useRef([]); // To keep track of previous transactions
    const {showToast} = useToast();
    const {fetchProfile} = useContext(ProfileContext);

    const _fetchTransactions = async () => {
        try {
            setLoading(true);            
            const transactions = await fetchTransactions();
            setTransactions(transactions); // Assuming the response contains the transactions
        } catch (err: any) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        // Immediately fetch transactions on mount
        _fetchTransactions();

        // Set up polling every 5 seconds
        const interval = setInterval(() => {
            _fetchTransactions();
        }, 5000); // 5 seconds in milliseconds

        // Cleanup interval on unmount
        return () => clearInterval(interval);
    }, []);

    // Compare previous and current transactions to detect finished ones
    useEffect(() => {
        if (prevTransactions.current?.length > 0) {
            const finishedTransactions = prevTransactions.current?.filter(
                (prevTx: any) => !transactions.find((tx: any) => tx.payload === prevTx.payload)
            );

            if (finishedTransactions?.length > 0) {
                // Emit an event or notify other components
                finishedTransactions.forEach((tx: any) => {
                    showToast({text: "Transaction finished", type: "success"});     
                    fetchProfile();               
                });
            }
        }

        // Update previous transactions with the current transactions
        prevTransactions.current = transactions;
    }, [transactions]);

    return { transactions, loading, error };
}
