import { Button } from "flowbite-react";
import { Link } from "react-router-dom";
import { ItemImage } from "../../Components/ItemImage/ItemImage";
import { HomeItemProps } from "./HomeItemProps";
import { HiArrowLeftCircle } from "react-icons/hi2";


export function HowDoestItWorks(props: HomeItemProps) {

    return <div className="w-full h-full grid md:grid-cols-2 grid-cols-1 auto-rows-min p-2 gap-2">
        <div className="flex flex-row gap-2">
            <Button color={"dark"} onClick={props.onBack} className="flex justify-start self-start text-white">
                <HiArrowLeftCircle className="text-2xl"/>
                <span className="ml-2">Back</span>
            </Button>
            <h1 className="text-3xl text-left font-bold tracking-tight text-white">
                How does it work?
            </h1>
        </div>
        <p className="text-base leading-relaxed text-left text-white">
            Every month, an airdrop of <strong>1850 NFT drop boxes</strong> will be carried out. These NFTs can be used in our video game and earn <strong>$BATH</strong> by playing.
        </p>
        <div className="dark bg-slate-800 border-0 self-center justify-self-center flex flex-col gap-2 pl-8 pr-8 pb-2 pt-2 rounded-lg">
            <ItemImage width={76} height={76} id={76} />
            <small className="text-gray-400 font-bold">TG Box</small>
        </div>
        <p className="text-base leading-relaxed text-left text-white">
            To participate, you must earn <strong>$TSK</strong>. The $TSK are points to position and qualify for the airdrop. The top 1000 on the leaderboard will be rewarded with the monthly Airdrop.
        </p>
        <div className="dark bg-slate-800 border-0 self-center justify-self-center flex flex-col gap-2 pl-8 pr-8 pb-2 pt-2 rounded-lg">
            <ItemImage width={76} height={76} id={77} />
            <small className="text-gray-400 font-bold">$TSK</small>
        </div>
        <p className="text-base leading-relaxed text-left text-white">
            To earn $TSK there are multiple ways. The best way is to play Battle Hero, for each game you will receive 25 $TSK.
        </p>
        <p className="text-base leading-relaxed text-left text-white">
            You can also complete daily tasks as well as invite your friends to receive extra $TSK and some other prizes.
        </p>
        <div className="flex flex-row gap-2 items-center justify-center">
            <Button as={Link} to="/play-to-earn" color={"dark"} className="flex-1">
                P2E
            </Button>
            <Button as={Link} to="/tasks" color={"dark"} className="flex-1">
                Tasks
            </Button>
            <Button as={Link} to="/referral" color={"dark"} className="flex-1">
                Referrals
            </Button>
        </div>
    </div>;
}
