import { Label, TextInput, Checkbox, Button } from "flowbite-react";
import { BattleHeroContext, useBattleHero } from "../../Hook/useBattleHero";
import { useContext, useState } from "react";
import { getQueryParam, getTgAppData, isAndroid } from "../../App";
import { AccountLinkedContext } from "../../Context/AccountLinkedContext";

export function LinkForm() {    
    const { link, error } = useContext(AccountLinkedContext);
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const referral = getQueryParam('referral');        
    const DOWNLOAD_URL = isAndroid() == true ? 'https://play.google.com/store/apps/details?id=com.LionGames.BattleHero.v2' : 'https://apps.apple.com/us/app/battle-hero/id6503414005';
    return (
        <div className="w-full flex flex-col gap-4">
            <h4 className="m-0 p-0 text-white font-bold">
                Enter your BattleHero account credentials
            </h4>
            <hr></hr>
            <div>
                <div className="mb-2 block text-left">
                    <Label className="text-white text-left" htmlFor="email1" value="Your Battle Hero Email" />
                </div>
                <TextInput
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    id="email1"
                    type="email"
                    placeholder="name@battlehero.com"
                    required
                />
            </div>
            <div>
            <div className="mb-2 block text-left">
                    <Label className="text-white text-left" htmlFor="password1" value="Your Battle Hero Password" />
                </div>
                <TextInput onChange={(e) => setPassword(e.target.value)} value={password} id="password1" type="password" required />
                {referral && <><div className="mb-2 block text-left">
                    <Label className="text-white text-left" htmlFor="referral" value="Referral" />
                </div>
                <TextInput readOnly disabled value={referral} id="referral" type="text" required />
                </>}
            </div>
            {error && <p className="text-red-500">Error linking account. Check credentials.</p>}
            
            <Button color={"warning"} className="text-black font-bold" onClick={async () => {
                await link(email, password, referral!);
            }} type="submit">Link account</Button>

            <a target="_blank" className="text-blue-400 text-sm cursor-pointer font-bold" href={DOWNLOAD_URL}>
                Doesnt have an account?
            </a>
        </div>
    );
}
