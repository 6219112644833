import { useEffect, useState } from "react";
import { fetchWrapper } from "../utils/fetchWrapper";
import { Inventory } from "../Core/Inventory/Inventory";

export default function useInventory(){
    const [inventory, setInventory] = useState<Inventory | null>(null);

    const fetchInventory = async () => {
        const endpoint = `${process.env.REACT_APP_API_URL}/inventory`;
        const request = await fetchWrapper(endpoint, {
            method: 'GET'
        });
        const response = await request.json();
        setInventory(response.data.inventory as Inventory);
    }

    useEffect(() => {
        fetchInventory();
    }, [])
    return {inventory, fetchInventory}
}