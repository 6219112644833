import { Card, Spinner } from "flowbite-react";
import { RiFacebookBoxFill, RiInstagramFill, RiTwitterXFill, RiYoutubeFill, RiTiktokFill } from "react-icons/ri";
// import { getImageById } from "../Tasks";
import { TaskItemProps } from "./TaskItemProps";
import { TaskItemModalRender } from "./TaskItemModalRender";
import { isAndroid } from "../../App";
import useTimeAway from "../../Hook/useTimeAway";
import { useContext, useEffect, useState } from "react";
import { GrValidate } from "react-icons/gr";
import { TaskRewards } from "./PlayGamesTaskItemModalRender";
import { completeTask } from "../../utils/api";
import { TasksContext } from "../../Context/TasksContext";



export interface FollowTaskItems{
    id: string;
    url: string;
    icon: any;
    text: string;
}


export class FollowUsTaskItemModalRender implements TaskItemModalRender {
    isTaskId(id: string): boolean {
        const tasksIds = [
            "328ed883-6ab5-4915-a51a-675d61177288",
            "277fc7bf-fb27-47ce-843a-b243606bb7e6",
            "aa5e3bad-71a4-4131-888d-996ca902a067", 
            "3b38c817-5a34-4535-9173-7b1b6f0e0dd9", 
            "791c51af-a5a6-4f59-87d3-5cf711522a10"
        ]
        return tasksIds.includes(id);
    }

    render(taskItem: TaskItemProps): JSX.Element {                
        return <FollowUsTaskItemModal taskItem={taskItem} />;
    }
}

export function FollowUsTaskItemModal(props: {taskItem: TaskItemProps}){
    const items: FollowTaskItems[] = [
        {id: "791c51af-a5a6-4f59-87d3-5cf711522a10",   url: `fb://${isAndroid() ? "page" : "profile"}/107672001620299`, icon: <RiFacebookBoxFill size={32} />, text: "Follow us on Facebook"},
        {id: "277fc7bf-fb27-47ce-843a-b243606bb7e6",   url: "https://www.instagram.com/battlehero/",                    icon: <RiInstagramFill size={32} />,   text: "Follow us on Instagram"},
        {id: "3b38c817-5a34-4535-9173-7b1b6f0e0dd9",   url: "twitter://user?screen_name=battleheroNFT",                 icon: <RiTwitterXFill size={32} />,    text: "Follow us on Twitter"},
        {id: "328ed883-6ab5-4915-a51a-675d61177288",   url: "https://www.youtube.com/@Battle_Hero",                     icon: <RiYoutubeFill size={32} />,     text: "Subscribe on Youtube"},
        {id: "aa5e3bad-71a4-4131-888d-996ca902a067",   url: "https://www.tiktok.com/@battlehero_official",              icon: <RiTiktokFill size={32} />,      text: "Follow us on TikTok"},
    ];
    const taskItem = props.taskItem;      
    const followItem = items.find(item => item.id === taskItem.id);    

    return (
        <div className="space-y-2">
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                {taskItem.largeDescription}
            </p>            
            <div className="flex flex-col gap-2">
                {followItem && <FollowUsSocialMediaItem taskId={taskItem.id} url={followItem.url} icon={followItem.icon} text={followItem.text}/>}
            </div>
            <TaskRewards taskItem={taskItem} />
        </div>
    )
}


export function FollowUsSocialMediaItem(props: { url: string, icon: JSX.Element, text: string, taskId: string }) {
    const [timeSpended, setTimeSpended] = useState<number>(0);
    const [startFollowing, setStartFollwing] = useState<boolean>(false);
    const {complete} = useContext(TasksContext);
    const MINIMUM_TIME_SPEND = 3;
    
    const FOLLOWED   = startFollowing && timeSpended >= MINIMUM_TIME_SPEND;
    const FOLLOWING  = startFollowing && timeSpended < MINIMUM_TIME_SPEND;
    const BAD_FOLLOW = timeSpended < MINIMUM_TIME_SPEND && startFollowing;

    const handleLinkClick = () => {
        const leaveTime = Date.now();
        localStorage.setItem(`leaveTime${props.url}`, leaveTime.toString());
        setStartFollwing(true);
    };

    useEffect(() => {
        if(!FOLLOWED){
            return;
        }
        complete(props.taskId);
    }, [FOLLOWED])

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                const leaveTime = localStorage.getItem(`leaveTime${props.url}`);
                if (leaveTime) {
                    const returnTime = Date.now();
                    const timeOutside = returnTime - parseInt(leaveTime, 10);
                    const timeSpended = Math.round(timeOutside / 1000);
                    setTimeSpended(timeSpended);                    
                    localStorage.removeItem(`leaveTime${props.url}`); // Clean up storage
                }
            }
        };
        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);

    return (
        <a target="_blank" rel="noopener noreferrer" href={props.url} onClick={handleLinkClick}>
            <Card className={FOLLOWED ? `bg-lime-100 border-lime-500` : ``}>
                <div className="flex items-center flex-row w-full justify-between">
                    {props.icon}
                    <div className="w-full flex flex-row items-center justify-end gap-4"> 
                        {FOLLOWING && <Spinner size="sm"/>}
                        {FOLLOWED && <GrValidate />}                        
                        <span className="text-base text-right font-bold leading-relaxed text-gray-500 dark:text-gray-400">{props.text}</span>                                                                
                    </div>
                </div>
            </Card>
            <div className="w-full flex flex-col">
            {BAD_FOLLOW && <span className={`text-sm text-red-500 text-right`}>
                            It seems you haven't followed us. Try to unfollow and follow us again
                </span>}
            </div>
        </a>
    );
}