
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import { BattleHeroProvider } from './Hook/useBattleHero';
import { Flowbite } from 'flowbite-react';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
    <TonConnectUIProvider manifestUrl="https://battlehero.io/tonconnect-manifest.json">
    <BattleHeroProvider>
      <Flowbite>
        <App />
      </Flowbite>
    </BattleHeroProvider>
    </TonConnectUIProvider>
    
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
