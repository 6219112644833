// import { getImageById } from "../Tasks";
import { TaskItemProps } from "./TaskItemProps";
import { TaskItemModalRender } from "./TaskItemModalRender";
import { TaskRewards } from "./PlayGamesTaskItemModalRender";


export class DefaultTaskItemModalRender implements TaskItemModalRender {
    isTaskId(id: string): boolean {
        return true;
    }
    render(taskItem: TaskItemProps): JSX.Element {
        return (
            <div className="space-y-2">
                <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                    {taskItem.largeDescription}
                </p>
                <TaskRewards taskItem={taskItem} />
            </div>
        );
    }
}
