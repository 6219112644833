import { useContext } from "react";
import { TransactionsContext } from "../Context/TransactionsContext";
import { GrTransaction } from "react-icons/gr";

export function TransactionIndicator(){
    const { transactions, openTransactions } = useContext(TransactionsContext);
    
    if(!transactions){
        return <></>
    }
    if(transactions?.length == 0){
        return <></>
    }
    return <div onClick={() => openTransactions()} className="self-center self-justify-start justify-self-start bg-sky-200/80 w-[22px] h-[22px] flex flex-row items-center justify-center rounded-full">
        <GrTransaction className="animate-spin text-lime-800" />
    </div>
}