import { Badge } from "flowbite-react";
import { Item } from "../../../Core/Inventory/Item";
import { HelperItemHandler } from "./HelperItemHandler";

export class MultiplierHelperItemHandler implements HelperItemHandler {
  isItemId = (itemId: number) => [74, 79].includes(itemId);
  render = (item: Item) => {    
    if(!item){
      return "";
    }
    if(!item.attributes){
      return "";
    }
    return <Badge className="dark bg-slate-800" color={"dark"}>x{item.attributes.multiplier} | {item.attributes.time / 3600}h</Badge>;
  };
}
