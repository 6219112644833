import { Modal, Progress } from "flowbite-react";
import { useContext, useState } from "react";
import { FaCircleInfo } from "react-icons/fa6";
import { TaskItemProps } from "./TaskItemProps";
import Countdown from "../../Components/Countdown";
import { FollowUsTaskItemModalRender } from "./FollowUsTaskItemModalRender";
import { DefaultTaskItemModalRender } from "./DefaultTaskItemModalRender";
import { TaskItemActions } from "./TaskItemActions";
import { TaskItemModalRender } from "./TaskItemModalRender";
import { PlayGamesTaskItemModalRender } from "./PlayGamesTaskItemModalRender";
import { claimTask } from "../../utils/api";
import { TasksContext } from "../../Context/TasksContext";


export function TaskItem(props: TaskItemProps) {
    const completed = props.progress ? props.progress >= 100 : 0;
    const [openInfo, setOpenInfo] = useState(false);    
    const {claim} = useContext(TasksContext);
    const claimedColor    = "green-700";
    const disabledColor   = "transparent";
    const inprogressColor = "slate-800";
    const completedColor  = "blue-600";
    const css = `${props.disabled ? "opacity-50 border" : ""} bg-${props.disabled ? disabledColor : (props.claimed ? claimedColor : (completed == true ? completedColor : inprogressColor))}`;    
    const renders: TaskItemModalRender[] = [
        new FollowUsTaskItemModalRender(),
        new PlayGamesTaskItemModalRender(),
        new DefaultTaskItemModalRender()
    ];
    const currentRender = renders.find(e => e.isTaskId(props.id));
    return <div className="flex flex-col w-full">
        <div className={`${css} transition ease-in-out delay-5 active:bg-slate-800 relative w-full border-l-4 border-yellow-400 shadow-md text-white pt-4 pb-4 pl-4 pr-4 rounded-xl`}>
        <div className="flex flex-row items-center">
        <div className="flex flex-row items-center flex-1 w-full">
            {props.icon && <props.icon className="text-2xl mr-4"></props.icon>}
            <div className="flex flex-col items-start justify-start">
                <strong className="text-left">{props.name}</strong>
                <small className="text-white-200 text-left">{props.description}</small>                
            </div>
        </div>
        <div className="flex flex-row gap-4 items-center position-relative">
            <TaskItemActions onClaim={(taskItemProps: TaskItemProps) => {
                claim(taskItemProps.id);
            }}  itemProps={props} onShowInfo={() => setOpenInfo(!openInfo)}/>
            <FaCircleInfo onClick={() => setOpenInfo(!openInfo)}/>
        </div>                
        </div>
        <div className="w-full h-4 rounded bg-slate-800 mt-2 border border-yellow-400">
            <div className="w-full h-full bg-yellow-400 rounded flex items-center justify-center" style={{width: `${props.progress}%`, maxWidth: "100%"}}>
                {props.claimed == true && completed == true && props.resetable && <Countdown initialSeconds={props.timeToReset}/>}
            </div>
        </div>  
        {completed == true && !props.claimed && <span className="absolute -top-1 right-0 flex h-3 w-3">
            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow-400 opacity-75"></span>
            <span className="relative inline-flex rounded-full h-3 w-3 bg-yellow-300"></span>
        </span>}
    </div>      
    <Modal theme={{
            root: {
                base: 'flex z-50 w-full',
            },
            content: {
                base: 'flex items-end justify-center w-full',
                inner: 'transition-all relative flex max-h-[90dvh] w-full flex-col rounded-t-lg bg-white shadow dark:bg-gray-700'
            }
        }} position={'bottom'} className="bg-slate-800 flex w-full" show={openInfo} onClose={() => setOpenInfo(false)}>
            <Modal.Header>{props.name}</Modal.Header>
            <Modal.Body>
              {currentRender?.render(props)}
            </Modal.Body>
        </Modal>  
    </div>;
}



