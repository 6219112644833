import { TaskItemProps } from "./TaskItemProps";
import { TaskItemModalRender } from "./TaskItemModalRender";
import { isAndroid } from "../../App";
import { ItemImage } from "../../Components/ItemImage/ItemImage";

export class PlayGamesTaskItemModalRender implements TaskItemModalRender {
    isTaskId(id: string): boolean {
        return id == "f3928f1e-c826-4b89-899a-8bd625f532d7";
    }
    render(taskItem: TaskItemProps): JSX.Element {
        const _isAndroid = isAndroid();
        return (
            <div className="space-y-2">
                <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                    {taskItem.largeDescription}
                </p>
                Play on {_isAndroid ? <a className="text-blue-500 font-bold" target="_blank" href="https://play.google.com/store/apps/details?id=com.LionGames.BattleHero.v2">Android</a> : <a className="text-blue-500 font-bold" target="_blank" href="https://apps.apple.com/us/app/battle-hero/id6503414005">iOS</a>}                
                <TaskRewards taskItem={taskItem} />
            </div>
        );
    }
}


export function TaskRewards(props: {taskItem: TaskItemProps}){
    const taskItem = props.taskItem;
    return <>
        <h2>Rewards: </h2>
        <div className="text-base flex justify justify-center gap-2 w-full text-center leading-relaxed text-gray-500 dark:text-gray-400">
            {taskItem.rewards?.map((reward, index) => <div className="text-center w-full rounded bg-slate-800 text-white p-2 flex flex-col items-center" key={index}>
                <ItemImage id={reward.id} width={22} /> x{reward.amount}
            </div>)}
        </div>
    </>
}