import { ItemName } from "./ItemName";

export class ItemNames {
    static MItemNames: ItemName[] = [
        new ItemName(1, "Gem"),
        new ItemName(4, "Trophies"),
        new ItemName(5, "Battle Pass Point"),
        new ItemName(6, "Experience"),
        new ItemName(7, "Box Hero"),
        new ItemName(8, "Box Super Hero"),
        new ItemName(9, "Box Ultra Hero"),
        new ItemName(10, "Cofre Lucky"),
        new ItemName(18, "Box Bath"),
        new ItemName(19, "Box Pe"),
        new ItemName(20, "Box Gem"),
        new ItemName(21, "Ticket Nft Common"),
        new ItemName(22, "Ticket Nft Low Rare"),
        new ItemName(23, "Ticket Nft Rare"),
        new ItemName(24, "Ticket Nft Epic"),
        new ItemName(25, "Ticket Nft Legendary"),
        new ItemName(26, "Ticket Nft Mythic"),
        new ItemName(28, "Energy Ticket"),
        new ItemName(29, "Box Bath Mini"),
        new ItemName(30, "Box Pe Mini"),
        new ItemName(31, "Box Gem Mini"),
        new ItemName(34, "Box Bath Super"),
        new ItemName(35, "Box Gem Super"),
        new ItemName(36, "Box Character"),
        new ItemName(37, "Box Weapon"),
        new ItemName(52, "Starter Box"),
        new ItemName(54, "Tester Box"),
        new ItemName(58, "Experience Mul."),
        new ItemName(59, "Trophy Locker"),
        new ItemName(62, "Hero Points"),
        new ItemName(69, "Power Box"),
        new ItemName(70, "Avatar Box"),
        new ItemName(71, "Avatar Nft Ticket"),
        new ItemName(72, "Skin Nft Ticket"),
        new ItemName(73, "Crypto Roulette Ticket"),
        new ItemName(74, "Bath Multiplier"),
        new ItemName(76, "TG Box"),
        new ItemName(77, "$TSK"),
        new ItemName(78, "$THUND"),
        new ItemName(79, "TSK Multiplier"),
        new ItemName(-99, "Nothing"),
    ];

    static Get(_Id: number): ItemName {
        const mFounded = ItemNames.MItemNames.find(e => e.itemId === _Id);
        if (!mFounded) {
            return new ItemName(-1, `${_Id}-name`);
        }
        return mFounded;
    }
}
