import { Item } from "../Core/Inventory/Item";

export function groupAndAccumulateItemsByIdAndAttributes(items: Item[]): Item[] {
    const groupedItems = items.reduce<Record<string, Item>>((acc, item) => {
        // Create a unique key based on id and stringified attributes
        const key = `${item.id}-${JSON.stringify(item.attributes)}`;

        if (!acc[key]) {
            // If the key is not in the accumulator, add a new entry with the current item
            acc[key] = { ...item };
        } else {
            // If the key exists, accumulate the amount
            acc[key].amount += item.amount;
        }
        return acc;
    }, {});

    // Convert the grouped object back to an array
    return Object.values(groupedItems);
}