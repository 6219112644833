import { useContext, useEffect, useState } from "react";
import { fetchWrapper } from "../utils/fetchWrapper";
import { useSend } from "./useSend";
import { BattleHeroContext } from "./useBattleHero";
import { TransactionsContext } from "../Context/TransactionsContext";
import { pollTransaction } from "../utils/api";
import { AccountLinkedContext } from "../Context/AccountLinkedContext";


// Use toncenter.com as HTTP API endpoint to interact with TON blockchain.
// You can get HTTP API key at https://toncenter.com
// You can run your own HTTP API instance https://github.com/toncenter/ton-http-api
    
export function useShop(){
    const[ products, setProducts ] = useState<any[]>([]);
    const { sendTransaction } = useSend();
    const { triggerDecreaseTHUND } = useContext(BattleHeroContext);
    const {refreshUser} = useContext(AccountLinkedContext);
    const fetchProducts = async () => {
        const endpoint = `${process.env.REACT_APP_API_URL}/ton/products`;
        const request = await fetchWrapper(endpoint, {
            method: 'GET'
        });
        const response = await request.json();
        const products = response.data.products;
        setProducts(products);
    }
    const purchase = async (withTon: boolean, productId: string, itemId: number, price: number, thundPrice?: number) => {
        const endpoint = `${process.env.REACT_APP_API_URL}/ton/purchase`;
        const request = await fetchWrapper(endpoint, {
            method: 'POST', 
            body: JSON.stringify({
                withTon: withTon, 
                productId: productId,                 
            })
        });
        refreshUser();
        const response = await request.json();
        if(!withTon){      
            console.log("Decrease thund price", thundPrice);
            triggerDecreaseTHUND(!thundPrice ? 0 : -thundPrice);      
            return;
        }
        const payload = response.data.payload;
        await sendTransaction('UQBvnn2S4zEm_EGRIr092AC8eFFT4vNsTX8QJ3ZWDfz16xY9', Number(price) * 1e9, payload);                
        await pollTransaction(response.data.signature, payload, {productId: productId, itemId: itemId});
    }    
    
    useEffect(() => {
        fetchProducts();
    }, [])
    return {products, purchase}
}