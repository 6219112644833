import { useTonConnectUI } from "@tonconnect/ui-react";
import React, { useContext, useEffect, useState } from "react";
import ContainerLayout from "../Components/Layouts/ContainerLayout";
import { AccountLinkedContext, AccountLinkedProvider } from "./AccountLinkedContext";
import { WalletContext, WalletProvider } from "./WalletContext";
import { isSessionValid } from "../utils/api";

export interface AuthenticationContextState {
    isAuthenticated: boolean
}
export const AuthenticationContext = React.createContext<AuthenticationContextState>({    
    isAuthenticated: false
});

export const AuthenticationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);    
    const {isLinked} = useContext(AccountLinkedContext);
    const {disconnect} = useContext(WalletContext);
    const _checkAuthenticated = async () => {
        const isAuth = await isSessionValid();  
        if(!isAuth){
            disconnect();
            return;
        } 
        setIsAuthenticated(isAuth);
    }
    useEffect(() => {        
        if(!isLinked){
            setIsAuthenticated(false);            
            return;
        }
        _checkAuthenticated();
    }, [isLinked])
    const state: AuthenticationContextState = {
        isAuthenticated
    }
    return <AuthenticationContext.Provider value={state}>            
                        {children}
         </AuthenticationContext.Provider>;
}
