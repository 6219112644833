import { useIsConnectionRestored, useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";
import React, { useEffect, useState } from "react";
import { fetchAccountInfo, fetchPayload, isAddressLinked } from "../utils/api";
import { Proofs } from "../utils/proofs";
import { AccountLinkedContext, AccountLinkedProvider } from "./AccountLinkedContext";
import { AuthenticationProvider } from "./AuthenticationContext";
import ContainerLayout from "../Components/Layouts/ContainerLayout";
import { ProfileProvider } from "./ProfileContext";

export interface WalletState {
    isConnected: boolean;
    requestConnection: () => void;
    disconnect: () => void;
    balance: number;
}
export const WalletContext = React.createContext<WalletState>({    
    isConnected: false,
    requestConnection: () => {}, 
    disconnect: () => {},
    balance: 0
});

export const WalletProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isConnected, setIsConnected] = useState(false);   
    const [tonConnectUI] = useTonConnectUI(); 
    const [balance, setBalance] = useState(0);
    const rawAddress = useTonAddress(false);
    const connectionRestored = useIsConnectionRestored();    
    
    const requestConnection = async () => {
        const payload = await fetchPayload();        
        tonConnectUI.setConnectRequestParameters({state: 'ready', value: {
            tonProof: payload.payloadToken
        }});        
        tonConnectUI.openModal();
    }
    const disconnect = () => {
        tonConnectUI.disconnect();
        localStorage.removeItem("session");
        setIsConnected(false);
    }
    const _checkProof = async () => {        
        if(localStorage.getItem("session") && !connectionRestored){            
            return;
        }        
        if(localStorage.getItem("session") && connectionRestored){            
            setIsConnected(true);
            return;
        }
        const session = await Proofs.verifyProof(tonConnectUI.wallet);        

        const linked  = await isAddressLinked(rawAddress);        
        if(session == false && linked != false && linked.linked == false){
            setIsConnected(true);
            return;
        }
        if(session == false){                        
            disconnect();                            
            return;
        }                
        setIsConnected(true);        
        localStorage.setItem('session', session);        
    }
    const _setBalance = async () => {
        const accountInfo = await fetchAccountInfo(rawAddress);        
        const _balance = accountInfo?.balance ? Number(accountInfo?.balance) / 1e9 : 0;
        setBalance(Number(_balance.toFixed(2)));
    }
    useEffect(() => {        
        if(!rawAddress){
            return;
        }        
        _setBalance();
        _checkProof();
    }, [rawAddress, connectionRestored]);   

    const state: WalletState = {
        isConnected, 
        requestConnection, 
        disconnect, 
        balance
    }
    return <WalletContext.Provider value={state}>
            <AccountLinkedProvider>
                <AuthenticationProvider>
                    <ProfileProvider>
                        <ContainerLayout>
                            {children}
                        </ContainerLayout>
                    </ProfileProvider>
                </AuthenticationProvider>
            </AccountLinkedProvider>
    </WalletContext.Provider>;
}
