import { Button } from "flowbite-react";
import { TaskItemActionsProps } from "./TaskItemActionsProps";


export function TaskItemActions(props: TaskItemActionsProps) {
    const completed = props.itemProps.progress ? props.itemProps.progress >= 100 : 0;
    if (props.itemProps.claimed == false && completed) {
        return <Button onClick={() => props.onClaim(props.itemProps)} disabled={props.itemProps.disabled} className="dark" color={"dark"}>Claim</Button>;
    }

    if (props.itemProps.claimed == true && completed) {
        return <span className="font-bold">Claimed</span>;
    }
    if (props.itemProps.claimed == false && completed == false) {
        return <Button onClick={props.onShowInfo}>GO!</Button>;
    }
    return <></>;

}
