import { useContext, useEffect, useRef, useState } from 'react'
import { Wheel } from 'react-custom-roulette'
import { useToast } from '../Context/ToastContext';
import { fetchWrapper } from '../utils/fetchWrapper';
import { Button, Card } from 'flowbite-react';
import { ItemImage } from './ItemImage/ItemImage';
import { useProfile } from '../Hook/useProfile';
import { useShop } from '../Hook/useShop';
import { ProfileContext } from '../Context/ProfileContext';
  
export const WheelComponent =() => {
    const [mustSpin, setMustSpin] = useState(false);
    const [prizeNumber, setPrizeNumber] = useState(0);    
    const [data, setData] = useState<any>([]);
    const [reward, setReward] = useState<any>([]);
    const {cryptoRouletteTickets, fetchProfile} = useContext(ProfileContext);
    const { purchase } = useShop();

    const fetchPrizes = async () => {
        const endpoint = `${process.env.REACT_APP_API_URL}/ton/roulette/rewards`;
        const response = await fetchWrapper(endpoint, {
            method: "GET"
        });
        const json = await response.json();
        const jsonData = json.data;
        const {hot, normal, ultra} = jsonData;
        const prizes = [...hot, ...normal, ...ultra];
        const datas = [];
        var index = 0;        
        const negativeNinetyNineRewards = prizes.filter(item => item.reward.id === -99);
        const otherRewards = prizes.filter(item => item.reward.id !== -99);

        // Create a new array to hold the organized rewards
        const organizedArray = [];

        // Interleave the -99 rewards with the other rewards
        let maxLength = Math.max(negativeNinetyNineRewards.length, otherRewards.length);
        for (let i = 0; i < maxLength; i++) {
            if (i < negativeNinetyNineRewards.length) {
                organizedArray.push(negativeNinetyNineRewards[i]);
            }
            if (i < otherRewards.length) {
                organizedArray.push(otherRewards[i]);
            }
        }
        for(var prize of organizedArray) {
            datas.push({
                id: prize.reward.id,
                option: prize.reward.id == -99 ? 'Nothing' : prize.reward.id,
                style: {backgroundColor: index % 2 == 0 ? '#3798f8' : '#0060e9'}, 
                image: prize.reward.id == -99 ? null : {
                    uri: `${process.env.REACT_APP_API_URL}/shop/image/${prize.reward.id}`, 
                    sizeMultiplier: 0.45, 
                    offsetY: 155 
                }
            });
            index++;
        }        
        setData(datas);
    }

    const throwRoulette = async () => {
        const endpoint = `${process.env.REACT_APP_API_URL}/ton/roulette/throw`;
        const response = await fetchWrapper(endpoint, {
            method: "POST"
        });
        const json = await response.json();     
        fetchProfile();    
        setReward(json.data.picked.reward);             
        return json.data.picked;
    }

 
    const handleSpinClick = async () => {    
      if (!mustSpin) {
        const reward = await throwRoulette();
        const index = data.findIndex((d: any) => d.id == reward.reward.id);
        setPrizeNumber(index);
        setMustSpin(true);        
      }
    }
    const handlePurchaseAndSpinClick = async () => {
      await purchase(true, 'b888dd17-8e65-4384-82c5-e4c747e58bf7', 73, 1, -1);
    }

    useEffect(() => {
        fetchPrizes();
    }, [])
 return <div className="flex flex-col items-center justify-center"> 
    <h1 className='font-bold text-white text-left text-2xl w-full p-4'>Roulette!</h1>
    {data.length > 0 && <Wheel
      mustStartSpinning={mustSpin}
      prizeNumber={prizeNumber}
      data={data}        
      onStopSpinning={() => {
        setMustSpin(false);
        if(reward.id == -99) return;
      }}
      backgroundColors={['#3e3e3e', '#df3428']}
      textColors={['#ffffff']}
    />}
    <div className='w-full p-4'>    
      <WheelButton cryptoRouletteTickets={cryptoRouletteTickets} onPurchaseAndSpin={handlePurchaseAndSpinClick} onSpin={handleSpinClick} />
    </div>
    <div className='flex flex-row gap-2 items-center text-white lilita-one-regular'>
      <span>Your tickets: {cryptoRouletteTickets}</span>
      <ItemImage id={73} width={12} height={12}></ItemImage>
    </div>    
  </div>
}



export interface WheelButtonProps{
  cryptoRouletteTickets: number
  onSpin: () => void
  onPurchaseAndSpin: () => void
}

export function WheelButton(props: WheelButtonProps){  
  if(props.cryptoRouletteTickets == 0){
    return <div className='flex flex-col gap-2'>
          <Button className='w-full' color={"warning"} onClick={props.onPurchaseAndSpin}>
            <span className='font-bold text-black w-full flex flex-row items-center gap-1'>
              Pay 1 <ItemImage id={"ton"} width={18} height={18}></ItemImage> to spin x1
            </span>
        </Button>
        {/* <div className='flex flex-row gap-2'>
        <Button className='w-full' color={"warning"} onClick={props.onPurchaseAndSpin}>
        <span className='font-bold text-black w-full flex flex-row items-center gap-1'>
              Pay 3 <ItemImage id={"ton"} width={18} height={18}></ItemImage> to spin x5
            </span>
        </Button>
        <Button className='w-full' color={"warning"} onClick={props.onPurchaseAndSpin}>
        <span className='font-bold text-black w-full flex flex-row items-center gap-1'>
              Pay 5 <ItemImage id={"ton"} width={18} height={18}></ItemImage> to spin x10
            </span>
        </Button>
        </div> */}
    </div>
  }
  return <Button className='w-full' color={"warning"} onClick={props.onSpin}>
      <span className='font-bold text-black'>Spin!</span>
  </Button>
}