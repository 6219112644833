import { fetchWrapper } from "./fetchWrapper";

export const fetchTasks = async () => {    
    const endpoint = `${process.env.REACT_APP_API_URL}/ton/tasks`;
    const request = await fetchWrapper(endpoint, {
        method: 'GET',
    });
    if(request.status != 200){
        return null;
    }
    const json = await request.json();        
    return json.data.tasks;    
}  
export const claimTask = async (taskId: any) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/ton/task`;
    const request = await fetchWrapper(endpoint, {
        method: 'POST',
        body: JSON.stringify({taskId: taskId})
    });
    const json = await request.json();   
    const rewards = json.data.rewards;
    return rewards;
}

export const fetchProducts = async () => {
    const endpoint = `${process.env.REACT_APP_API_URL}/ton/products`;
    const request = await fetchWrapper(endpoint, {
        method: 'GET'
    });
    const response = await request.json();
    const products = response.data.products;
    return products;
}

export const purchase = async (withTon: boolean, productId: number) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/ton/purchase`;
    const request = await fetchWrapper(endpoint, {
        method: 'POST', 
        body: JSON.stringify({
            withTon: withTon, 
            productId: productId,                 
        })
    });
    return request;
}

export const fetchTransactions = async () => {    
    const endpoint = `${process.env.REACT_APP_API_URL}/ton/transactions`;
    const response = await fetchWrapper(endpoint, { method: 'GET' });
    const json     = await response.json();        
    return json.data.transactions;
};


export const fetchReferralCounter = async () => {    
    const endpoint = `${process.env.REACT_APP_API_URL}/ton/referrals/counter`;
    const response = await fetchWrapper(endpoint, { method: 'GET' });
    const json     = await response.json();        
    return json.data.counter;
};

export const  pollTransaction = async (signature: string, payload: any, extraData?: any) => {    
    const endpoint = `${process.env.REACT_APP_API_URL}/ton/transactions`;
    const request = await fetchWrapper(endpoint, { method: 'POST', body: JSON.stringify({
        signature: signature, 
        payload: payload, 
        extraData: extraData ?? {}
    })});
    return request;
}

export const fetchProfile = async () => {
    const endpoint = `${process.env.REACT_APP_API_URL}/player/`;
    const response = await fetchWrapper(endpoint, {
        method: 'GET'      
      });
      const json = await response.json();          
      return json.data;
}

export const fetchInventory = async () => {
    const endpoint = `${process.env.REACT_APP_API_URL}/inventory`;
    const request = await fetchWrapper(endpoint, {
        method: 'GET'
    });
    const response = await request.json();
    return response.data.inventory;
}

export const linkBattleHeroAccount = async (email: string,  password: string, rawAddress: string, referral?: string) => {       
    const request = await fetch(`${process.env.REACT_APP_API_URL}/ton/link`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({email, password, address: rawAddress as string, referral: referral})
    })
    if(request.status !== 200){
        return false;
    }
    if(request.status === 200){
        const response = await request.json();
        const session = response.data.session;  
        localStorage.setItem('session', session);
        return true;
    }        
    return false;
}

export const isSessionValid = async () => {
    const endpoint = `${process.env.REACT_APP_API_URL}/ton/tasks`;
    const request = await fetchWrapper(endpoint, {
        method: 'GET',
    });
    if(request.ok){
        return true;
    }
    return false;
}

export const isAddressLinked = async (rawAddress: string) => {
    const request = await fetch(`${process.env.REACT_APP_API_URL}/ton/linked?address=${rawAddress}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },            
    });
    if(request.status !== 200){
        return false;
    }
    const json = await request.json();
    return json.data;    
}
export const playCounter = async () => {
    const request = await fetchWrapper(`${process.env.REACT_APP_API_URL}/ton/play/counter`, {
        method: 'GET',                
    });    
    const json = await request.json();
    return {counter: json.data.counter, earned: json.data.earned};    
}

export const fetchAccountInfo = async (address: string) => {

    const endpoint = `https://testnet.toncenter.com/api/v2/getAddressInformation?address=${address}`;
    const request = await fetch(endpoint, {
        method: 'GET',
    });
    const json = await request.json();
    return json.result;
}

export const completeTask = async (taskId: string) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/ton/complete/task`;
    const request = await fetchWrapper(endpoint, {
        method: 'POST',
        body: JSON.stringify({taskId: taskId})
    });
    await request.json();    
}




// Wallet no conectada
// Wallet conectada y no linkado ( sin session )
// Wallet conectada y linkado

export const fetchPayload = async () => {
    const endpoint = `${process.env.REACT_APP_API_URL}/ton/payload`;
    const request = await fetch(endpoint, {
        method: 'GET',
    });
    const json = await request.json();
    return json.data as {payload: string, payloadToken: string};
}

