import React, { useContext, useEffect, useState } from 'react';
import { NavFooterMenu } from '../Footer/NavFooterMenu';
import { Navbar } from 'flowbite-react';
import { Logo } from '../Logo/Logo';
import { ConnectButton } from '../Footer/ConnectButton';
import { TransactionsContext, TransactionsProvider } from '../../Context/TransactionsContext';
import { GrTransaction } from "react-icons/gr";
import { TransactionIndicator } from '../TransactionIndicator';
import { AuthenticationContext } from '../../Context/AuthenticationContext';
import { AccountLinkedContext } from '../../Context/AccountLinkedContext';
import { InitialSteps } from '../Initial/InitialSteps';
import { WalletContext } from '../../Context/WalletContext';
import { TskCounter } from '../Boxes/TskCounter';
import { ThundCounter } from '../Boxes/ThundCounter';
import { TonCounter } from '../Boxes/TonCounter';
import { useLocation } from 'react-router-dom';

interface ContainerLayoutProps {
    children: React.ReactNode;
}

const ContainerLayout: React.FC<ContainerLayoutProps> = ({ children }) => {    
    const { isAuthenticated } = useContext(AuthenticationContext);    
    const { isLinked } = useContext(AccountLinkedContext);
    const { isConnected } = useContext(WalletContext);        

    return (
        <div className="flex flex-col h-screen overflow-hidden">
            {/* Top Header */}
            <header className="sticky top-0 z-[9999999]">
                <div className="max-w-7xl mx-auto px-4 py-2 sm:px-6 lg:px-8 flex flex-row justify-between">
                    <Logo />
                    <div className='text-white flex flex-row gap-2'>
                        {isAuthenticated && <>
                        <TonCounter/>
                        <TskCounter/>
                        <ThundCounter/>
                        </>}
                    </div>
                </div>
            </header>

            {/* Content Area */}
            <main className={`flex-1 overflow-y-auto`}>
                {isAuthenticated  && isLinked  && isConnected  && children}
                {!isAuthenticated && !isLinked && isConnected  && <InitialSteps/>}
                {!isAuthenticated && !isLinked && !isConnected && <div className='flex flex-col gap-4 w-full h-full justify-center items-center'>
                    <h1 className='text-white/60'>Connect your wallet first</h1>
                    <ConnectButton/>
                </div>}
            </main>

            {/* Bottom Navigation */}
            <TransactionsProvider>
                <nav className="sticky bottom-0 z-50 bg-slate-800 shadow-t-md">
                    <div className="dark flex justify-between items-center max-w-7xl mx-auto px-4 py-2 sm:px-6 lg:px-8">
                        <Navbar className='w-full'>
                            <div className='flex flex-row justify-between w-full'>
                                <div className='flex flex-row gap-2'>
                                    {isAuthenticated && <NavFooterMenu />}
                                    <TransactionIndicator/>
                                </div>
                                <ConnectButton />
                            </div>
                        </Navbar>
                    </div>
                </nav>
            </TransactionsProvider>
        </div>
    );
};

export default ContainerLayout;
