import { useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";
import React, { useContext, useEffect, useState } from "react";
import { WalletContext } from "./WalletContext";
import { isAddressLinked, linkBattleHeroAccount } from "../utils/api";

export interface AccountLinkedState {
    isLinked: boolean;
    error: boolean;
    linkedUser: any;
    refreshUser: () => void;
    link: (email: string, password: string, referral?: string) => Promise<void>;
}
export const AccountLinkedContext = React.createContext<AccountLinkedState>({    
    isLinked: false,
    error: false,
    linkedUser: {}, 
    refreshUser: () => {},
    link: async (email: string, password: string, referral?: string) => {}
});

export const AccountLinkedProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isLinked, setIsLinked] = useState(false); 
    const [error, setError] = useState(false);
    const [linkedUser, setLinkedUser] = useState<any>({});
    const { isConnected } = useContext(WalletContext);   
    const rawAddress = useTonAddress(false);

    const _checkLinked = async () => {
        const linkedUser = await isAddressLinked(rawAddress);
        if(linkedUser != false){
            setIsLinked(linkedUser.linked);
            if(linkedUser.linked == true){
                setLinkedUser(linkedUser.user);
            }
        }
    }

    const refreshUser = async () => {
        const linkedUser = await isAddressLinked(rawAddress);
        if(linkedUser != false){
            setIsLinked(linkedUser.linked);
            if(linkedUser.linked == true){
                setLinkedUser(linkedUser.user);
            }
        }
    }

    const link = async (email: string, password: string, referral?: string) => {
        const linked = await linkBattleHeroAccount(email, password, rawAddress, referral);
        if(linked){
            _checkLinked();
        }else{
            setError(true);
        }
    }

    useEffect(() => {        
        if(!isConnected){            
            setIsLinked(false);
            return;
        }
        _checkLinked();
    }, [isConnected])
    const state: AccountLinkedState = {
        isLinked, 
        linkedUser, 
        refreshUser,
        error,
        link
    }
    return <AccountLinkedContext.Provider value={state}>
        {children}
    </AccountLinkedContext.Provider>;
}
