import { Spinner } from "flowbite-react";
import { ItemImage } from "./ItemImage/ItemImage";

export interface TransactionItemProps{
    transaction: any;
}
export function TransactionItem(props: TransactionItemProps){
    const renders: TransactionItemExtraDataRender[] = [new ProductTransactionItemExtraDataRender()];
    console.log(props);
    const render = renders.find(e => e.isExtraData(props.transaction?.extraData));
    return <div className="border rounded m-2 p-2">
            {props.transaction.status == "pending" && <div className="flex flex-row gap-4"><Spinner/> <span>Pending</span> {render?.render(props.transaction?.extraData)} </div>}
    </div>
}

export interface TransactionItemExtraDataRender{
    isExtraData(data: any): boolean;
    render(data: any): JSX.Element;
}

export class ProductTransactionItemExtraDataRender implements TransactionItemExtraDataRender{
    isExtraData(data: any): boolean {
        if(!data){
            return false;
        }
        return 'itemId' in data;
    }
    render(data: any): JSX.Element {
        console.log(data);
        const { itemId } = data;
        return <ItemImage id={itemId} width={24}/>
    }
}