import React, { useState, useEffect } from 'react';

interface CountdownProps {
  initialSeconds: number; // The initial time in seconds for the countdown
  onFinish?: () => void; // Callback function to be called when the countdown reaches zero
}

const Countdown: React.FC<CountdownProps> = ({ initialSeconds, onFinish }) => {  
  const [seconds, setSeconds] = useState(initialSeconds);  
  useEffect(() => {
    // Create an interval that decreases the seconds by 1 every 1000 milliseconds (1 second)
    const interval = setInterval(() => {
      setSeconds((prevSeconds) => (prevSeconds > 0 ? prevSeconds - 1 : 0));
    }, 1000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // Call the onFinish callback function when the countdown reaches zero
    if (seconds === 0) {
      onFinish && onFinish();
    }
  }, [seconds, onFinish]);

  // Calculate days, hours, minutes, and seconds from the total seconds
  const days = Math.floor(seconds / 86400); // 86400 seconds in a day
  const hours = Math.floor((seconds % 86400) / 3600); // remaining hours
  const minutes = Math.floor((seconds % 3600) / 60); // remaining minutes
  const remainingSeconds = seconds % 60; // remaining seconds

  // Format the countdown string with days, hours, minutes, and seconds
  const formatNumber = (num: number) => String(num).padStart(2, '0');
  const countdownString = days > 0 
    ? `${days}d ${formatNumber(hours)}h ${formatNumber(minutes)}m ${formatNumber(remainingSeconds)}s`
    : `${formatNumber(hours)}h ${formatNumber(minutes)}m ${formatNumber(remainingSeconds)}s`;  
  return (
    <div className='font-bold'>      
      <p>{countdownString}</p>
    </div>
  );
};

export default Countdown;
