import { useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";
import React, { useEffect, useState } from "react"
import { Proofs } from "../utils/proofs";
import { fetchWrapper } from "../utils/fetchWrapper";


export interface BattleHeroState {
    increaseTsk: number;    
    decreaseThund: number;
    increaseThund: number;
    triggerIncreaseTSK: (amount: number) => void;
    triggerIncreaseTHUND: (amount: number) => void;
    triggerDecreaseTHUND: (amount: number) => void;    
}
export const BattleHeroContext = React.createContext({
} as BattleHeroState);


export function BattleHeroProvider({children}: {children: React.ReactNode}) {
    const state = useBattleHero();
     return <BattleHeroContext.Provider value={state}>{children}</BattleHeroContext.Provider>;
}

export function useBattleHero(){
    const [increaseTsk, setIncreaseTsk] = useState<number>(0);
    const [increaseThund, setIncreaseThund] = useState<number>(0);
    const [decreaseThund, setDecreaseThund] = useState<number>(0);    
    

    const triggerIncreaseTSK = (amount: number) => {
        setIncreaseTsk(amount);
        setTimeout(() => setIncreaseTsk(0), 3000);
    }
    const triggerDecreaseTHUND = (amount: number) => {
        setDecreaseThund(amount);
        setTimeout(() => setDecreaseThund(0), 3000);        
    }
    const triggerIncreaseTHUND = (amount: number) => {
        setIncreaseThund(amount);
        setTimeout(() => setIncreaseThund(0), 3000);        
    }

    return {
        triggerIncreaseTSK,
        triggerIncreaseTHUND,
        triggerDecreaseTHUND,
        increaseThund,
        decreaseThund,
        increaseTsk
    }
}
