export interface ItemImageProps{
    id: number | string;
    width?: number;
    height?: number;
    helper?: string | JSX.Element;
}
export function ItemImage(props: ItemImageProps) {
    const endpointImage = `${process.env.REACT_APP_API_URL}/shop/image/${props.id}`
    const width = props.width ? `${props.width}px ` : '';
    const height = props.height ? `${props.height}px ` : '';    
    return <div className={`relative ${width} ${height}`}> 
        <img style={{width: width, height: height}} src={endpointImage}></img>
        {props.helper ? <div className="absolute -bottom-3 left-0 text-white text-sm font-bold">
            {props.helper}
        </div> : ''}
    </div>;
}
