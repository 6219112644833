import './App.css';
import { InitialSteps } from './Components/Initial/InitialSteps';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { Home } from './Pages/Home';
import { Tasks } from './Pages/Tasks';
import { Airdrop } from './Pages/Airdrop';
import { Wheel } from './Pages/Wheel';
import { Shop } from './Pages/Shop';
import { Inventory } from './Pages/Inventory';
import { ToastProvider } from './Context/ToastContext';
import { Referral } from './Pages/Referral';
import { WalletProvider } from './Context/WalletContext';
import { TasksProvider } from './Context/TasksContext';
import { PlayToEarn } from './Pages/PlayToEarn';
import { useRef } from 'react';
import { AudioProvider } from './Context/AudioContext';



const router = createBrowserRouter([
  {
    path: "/",
    
    element: <WalletProvider>
          <Home />
      </WalletProvider>,
  },
  {
    path: "tasks",
    element: <WalletProvider>
            <TasksProvider>
              <Tasks />
            </TasksProvider>
      </WalletProvider>,
  },
  {
    path: "airdrop",
    element: <WalletProvider><Airdrop /></WalletProvider>,
  },
  {
    path: "wheel",
    element: <WalletProvider>
        <Wheel />
    </WalletProvider>,
  },
  {
    path: "shop",
    element: <WalletProvider>
        <Shop />
      </WalletProvider>,
  },
  {
    path: "inventory", 
    element: <WalletProvider>
        <Inventory/>
    </WalletProvider>,
  },
  {
    path: "referral", 
    element: <WalletProvider>
        <Referral/>
    </WalletProvider>
  },
  {
    path: "initial", 
    element: <WalletProvider>
        <InitialSteps/>
    </WalletProvider>
  },
   {
     path: "play-to-earn",
     element: <WalletProvider>
        <PlayToEarn/>
     </WalletProvider>
   }
]);



export const getTgAppData = (key?: string) => {
  const hash = window.location.hash.slice(1);  
  const params = new URLSearchParams(hash);
  if(!key){
    return params;
  }
  return params.get(key);
}

export const isAndroid = () => {
  const platform = getTgAppData('tgWebAppPlatform');
  return platform === 'android';
} 


export const getQueryParam = (key: string) => {
  // Get query string from the URL
  const searchParams = new URLSearchParams(window.location.search);

  // Get query string from the hash fragment (if it contains one)
  const hash = window.location.hash;  
  const hashParams = hash.includes('?') ? new URLSearchParams(hash.split('?')[1]) : null;

  // Check if the param exists in the URL query params first, then fall back to the hash
  return searchParams.get(key) || (hashParams ? hashParams.get(key) : null);
};

function App() {  

  return (
    <ToastProvider>     
      <AudioProvider>
          <div className="App">                                               
              <RouterProvider future={{ v7_startTransition: true }} router={router}/>                          
              <audio id="background-music" autoPlay loop>
                <source src="./assets/bso.mp3" type="audio/mpeg" />
                Your browser does not support the audio element.
            </audio>          
          </div>      
        </AudioProvider>     
    </ToastProvider>
  );
}

export default App;
