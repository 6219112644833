import { useEffect, useState } from "react";
import { fetchWrapper } from "../utils/fetchWrapper";

export function useProfile(): { fetchProfile: any, offChainBathBalance: any, offChainPeBalance: any, gemsBalance: any, email: any, nickname: any, selectedAvatarId: any, cryptoRouletteTickets: any } {
    const [user, setUser] = useState<any>({});    

    const fetchProfile = async () => {
        const endpoint = `${process.env.REACT_APP_API_URL}/player/`;
        const response = await fetchWrapper(endpoint, {
            method: 'GET'      
          });
          const json = await response.json();          
          setUser(json.data);
    }

    useEffect(() => {
        fetchProfile();
    }, []);

    return {        
        fetchProfile,        
        offChainBathBalance: (Number(user?.wallet?.balances?.bath)  / 1e18).toFixed(2), 
        offChainPeBalance: (Number(user?.wallet?.balances?.pe)      / 1e18).toFixed(2),         
        gemsBalance: (Number(user?.wallet?.balances?.gems)).toFixed(2),
        email: user?.email,
        nickname: user?.player?.nickname, 
        selectedAvatarId: user?.player?.avatar,
        cryptoRouletteTickets: user?.player?.cryptoRouletteTickets        
    };    
}