import { Navbar, Sidebar } from "flowbite-react";
import { HiChartPie, HiShoppingBag } from "react-icons/hi";
import { useState } from "react";
import { HiMiniLifebuoy } from "react-icons/hi2";
import { HiMiniPaperAirplane } from "react-icons/hi2";
import { Link } from "react-router-dom";
import { HiArchiveBox } from "react-icons/hi2";
import { GoCrossReference } from "react-icons/go";
import { IoLogoGameControllerA } from "react-icons/io";
import { useAudio } from "../../Context/AudioContext";

export function NavFooterMenu() {
    const [opened, setOpened] = useState(false);
    const {playAudio} = useAudio();
    
    const _handleClickItem = () => {
        setOpened(false);
        playAudio('Button_Generic');
    }

    return (
        <>
         <div className={`w-screen z-50 h-screen fixed bottom-0 sidenavdrawer ${opened ? "opened" : "closed"} flex`}>
            <Sidebar theme={{
                root: {
                    inner: 'h-full z-50 overflow-y-auto overflow-x-hidden rounded bg-slate-800 px-3 py-4 dark:bg-gray-800 flex items-end',                                                                 
                },
                items:{
                    base: 'justify-end items-end z-50'
                }
            }} className={`text-left`} aria-label="Default sidebar example">
                <Sidebar.Items>
                    <Sidebar.ItemGroup>
                        <Sidebar.Item onClick={_handleClickItem} as={Link} to="/play-to-earn" icon={IoLogoGameControllerA}>
                            <span className="text-white">Play To Earn</span>
                        </Sidebar.Item>
                        <Sidebar.Item onClick={_handleClickItem} as={Link} to="/tasks" icon={HiChartPie}>
                            <span className="text-white">Tasks</span>
                        </Sidebar.Item>
                        <Sidebar.Item onClick={_handleClickItem} as={Link} to="/airdrop" icon={HiMiniPaperAirplane}>
                            <span className="text-white">Airdrop</span>
                        </Sidebar.Item>
                        <Sidebar.Item onClick={_handleClickItem} as={Link} to="/inventory" icon={HiArchiveBox}>
                            <span className="text-white">Inventory</span>
                        </Sidebar.Item>
                        <Sidebar.Item onClick={_handleClickItem} as={Link} to="/shop" icon={HiShoppingBag}>
                            <span className="text-white">Shop</span>
                        </Sidebar.Item>                        
                        <Sidebar.Item onClick={_handleClickItem} as={Link} to="/wheel" icon={HiMiniLifebuoy}>
                            <span className="text-white">Roulette</span>
                        </Sidebar.Item> 
                        <Sidebar.Item onClick={_handleClickItem} as={Link} to="/referral" icon={GoCrossReference}>
                            <span className="text-white">Referral</span>
                        </Sidebar.Item>                        
                    </Sidebar.ItemGroup>
                </Sidebar.Items>
            </Sidebar>
            <div onClick={() => setOpened(false)} className="overlay w-full h-full flex-1 w-64 bg-black opacity-50">                
            </div>
            </div>
            <div className="flex flex-row gap-4">
            <Navbar.Toggle className="border border-gray-600" onClick={() => {
                setOpened(!opened);
                playAudio('Button_Generic');
            }} />            
            </div>
        </>
    );
}
