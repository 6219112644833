
import { Spinner, Toast } from "flowbite-react";
import { HiCheck, HiExclamation, HiX } from "react-icons/hi";
import { ToastProps } from "./ToastProps";


export function LoadingToast(props: ToastProps) {
  return (
    <div className="flex flex-col gap-4">
      <Toast>
        <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-cyan-100 text-cyan-500 dark:bg-cyan-800 dark:text-cyan-200">
            <Spinner size={"sm"} />
        </div>  
        <div className="ml-3 text-sm font-normal">{props.text}</div>
        <Toast.Toggle onClick={props.onDismis} />
      </Toast>
    </div>
  );
}
