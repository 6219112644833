import React, {  } from "react";
import { useTasks } from "../Hook/useTasks";

export interface TasksContextState {    
    tasks: any[];
    loading: boolean;
    claim: (id: string) => Promise<any>;    
    complete: (id: string) => Promise<any>;
}

export const TasksContext = React.createContext<TasksContextState>({    
    tasks: [],
    loading: false,
    claim: async () => {}
    ,complete: async () => {}
});

export const TasksProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    
    const state: TasksContextState = useTasks();

    return <TasksContext.Provider value={state}>            
                        {children}
         </TasksContext.Provider>;
}
