import { Button, Card } from "flowbite-react";
import { useSend } from "../Hook/useSend";
import { useShop } from "../Hook/useShop";
import { ItemImage } from "../Components/ItemImage/ItemImage";
import { ItemNames } from "../Core/Shared/ItemNames";
import { HelperItemHandler } from "../Components/ItemImage/Helpers/HelperItemHandler";
import { MultiplierHelperItemHandler } from "../Components/ItemImage/Helpers/MultiplierHelperItemHandler";
import { BlankHelperItemHandler } from "../Components/ItemImage/Helpers/BlankHelperItemHandler";

export function Shop(){
    const {products, purchase} = useShop();
    const helpers: HelperItemHandler[] = [
        new MultiplierHelperItemHandler(), 
        new BlankHelperItemHandler()
    ];
    return <div className="grid grid-cols-1 gap-4 p-2 max-h[calc(100vh-385px)] overflow-auto">        
        {products.map((e: any, index: number) => {
            const helper = helpers.find((h) => h.isItemId(e.item.id));            
            return <Card key={index} className="bg-sky-800 border-l-4 border-t-0 border-r-0 border-b-0 border-yellow-400 text-white">                
                <div className="w-full items-center flex justify-between">
                    <span>
                    {ItemNames.Get(e.item.id).name}
                    </span>
                    <span className="text-left text-black font-bold bg-yellow-400 px-2 rounded-full">
                        x{e.item.amount}
                    </span>
                </div>
                <div className="w-full items-center flex justify-center">
                    <ItemImage helper={helper?.render(e.item) ?? ''} id={e.item.id} width={64}></ItemImage>
                </div>
                <hr className="opacity-10"></hr>
                <div className="flex flex-row justify-between">
                    <Button onClick={() => {
                             purchase(true, e.id, e.item.id, e.tonPrice);
                    }}  className="dark" color="dark">
                        <div className="flex flex-row gap-2 items-center">
                            <span className="font-bold">{e.tonPrice}</span>
                            <ItemImage id="ton" width={12}></ItemImage>
                        </div>
                    </Button>                
                    <span className="font-bold m-2">OR</span>
                    <Button onClick={() => {
                            console.log(e);
                             purchase(false, e.id, e.item.id, e.tonPrice, e.thundPrice);
                    }}   className="dark" color="dark">
                        <div className="flex flex-row gap-2 items-center">
                            <span className="font-bold">{e.thundPrice}</span>
                            <ItemImage id="78" width={12}></ItemImage>
                        </div>
                    </Button>
                </div>
            </Card>
        })}
    </div>
}