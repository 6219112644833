import { useContext, useEffect, useState } from "react"
import { BattleHeroContext } from "./useBattleHero";
import { claimTask, completeTask, fetchTasks } from "../utils/api";
import { AccountLinkedContext } from "../Context/AccountLinkedContext";

export function useTasks(){
    const [tasks, setTasks] = useState([]);
    const [loading, setLoading] = useState(false);
    const {triggerIncreaseTSK, triggerIncreaseTHUND} = useContext(BattleHeroContext);
    const {refreshUser} = useContext(AccountLinkedContext);
    const _fetchTasks = async () => {
        setLoading(true);                
        const tasks = await fetchTasks();
        if(tasks == null){
            setLoading(false);
            return;
        }
        setTasks(tasks);
        setLoading(false);
    }    

    const complete = async (taskId: string) => {
        await completeTask(taskId);
        _fetchTasks();
    }


    const claim = async (taskId: string) => {        
        const rewards = await claimTask(taskId)
        const tsk = rewards.find((e: any) => e.id == 77)?.amount ?? 0;
        const thund = rewards.find((e: any) => e.id == 78)?.amount ?? 0;
        _fetchTasks();     
        triggerIncreaseTSK(tsk);
        triggerIncreaseTHUND(thund);
        refreshUser();
    }

    useEffect(() => {
        _fetchTasks();
    }, [])

    return {tasks, claim, complete, loading}
}