import { useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";
import { Dropdown, Button } from "flowbite-react";
import { HiClipboardCopy, HiOutlineExternalLink } from "react-icons/hi";
import { Proofs } from "../../utils/proofs";
import { useContext } from "react";
import { BattleHeroContext } from "../../Hook/useBattleHero";
import { WalletContext } from "../../Context/WalletContext";

export function ConnectButton() {
    const userFriendlyAddress = useTonAddress();        
    const { requestConnection, isConnected, disconnect } = useContext(WalletContext);    


    const shortUserFriendlyAddress = (address: string) => {
        return address.slice(0, 6) + '...' + address.slice(-4);
    };

    if (isConnected) {
        return <Dropdown color={'warning'} label={shortUserFriendlyAddress(userFriendlyAddress as string)}>
            <Dropdown.Item onClick={() => {
                navigator.clipboard.writeText(userFriendlyAddress as string);
            }}>
                <HiClipboardCopy />
                Copy address
            </Dropdown.Item>
            <Dropdown.Item onClick={() => {
                disconnect();
            }}>
                <HiOutlineExternalLink /> Disconnect
            </Dropdown.Item>

        </Dropdown>;
    }
    return <Button onClick={requestConnection}>
        Connect
    </Button>;
}
