import { TaskItem } from "./TaskItem";
import { TaskItemProps } from "./TaskItemProps";
import { TaskListProps } from "./TaskListProps";


export function TaskList(props: TaskListProps) {
    if (props.tasks.length == 0) {
        return <div className="w-full h-full justify-center text-white/70 font-bold p-4 flex flex-col items-center">No tasks</div>;
    }
    return (<>
        {props.tasks.map((e: TaskItemProps) => <TaskItem {...e} key={e.id}></TaskItem>)}
    </>);
}
