import { useContext } from 'react';
import { BattleHeroContext, useBattleHero } from '../../Hook/useBattleHero';
import yellow from './yellow.png';
import { AccountLinkedContext } from '../../Context/AccountLinkedContext';
import { formatNumber } from '../../utils/formatNumber';
export function ThundCounter(){
    const {increaseThund, decreaseThund} = useContext(BattleHeroContext);        
    const {linkedUser} = useContext(AccountLinkedContext);    
    if(!linkedUser?.balance) return <></>
    return <div className='flex flex-row bg-slate-800 pt-2 pb-2 pr-2 pl-4 rounded-lg relative z-50'>
        <span>{formatNumber(linkedUser?.balance?.thundPoints)}</span> <img className="h-6 ml-2" src={yellow}></img>
        {increaseThund > 0 && <div className='animate-bounce absolute rounded -bottom-8 left-0 w-full bg-slate-800'>
            +{increaseThund}
        </div>}
        {decreaseThund < 0 && <div className='animate-bounce absolute rounded -bottom-8 left-0 w-full bg-slate-800'>
            {decreaseThund}
        </div>}
        </div>
}