import { useEffect, useState } from "react";
import { ItemImage } from "../Components/ItemImage/ItemImage";
import { playCounter } from "../utils/api";

export function PlayToEarn(){
    const [counter, setCounter] = useState(0);
    const [earned, setEarned] = useState(0);
    const fetchCounter = async () => {
        const {counter:_counter, earned: _earned} = await playCounter();
        setCounter(_counter);
        setEarned(_earned);
    }

    useEffect(() => {
        fetchCounter();
    }, [])

    return <div className="p-2">  
        <h2 className="text-white text-left text-2xl font-bold mb-2">
            Play To Earn!
        </h2>
        <div className="h-full flex flex-col items-center gap-4">
            <p className="text-white text-left">
                Get more TSK by playing. For each game you play you will receive <strong>+25 TSK</strong>. You can play as many as you want.
            </p>
            <video autoPlay playsInline loop muted>
                <source src="https://battlehero.io/wp-content/uploads/2024/03/Hero_Sniper_Movie_055-1_1.webm" type='video/webm'/>                            
            </video>
            <h2 className="text-white text-left text-2xl font-bold">Stats</h2>
            <div className="w-full items-center justify-center flex flex-row gap-4">
                <CircularIndicator value={counter} label="Playeds"/>
                <CircularIndicator value={earned} label={<ItemImage id={77} width={32} height={32} />}/>
            </div>
        </div>
    </div>
}

export interface CircularIndicatorProps{
    value: number;
    label: JSX.Element | string;
}
export function CircularIndicator(props: CircularIndicatorProps){
    return <div className="border flex flex-col items-center justify-center rounded-full w-[140px] h-[140px]">
            <small className="text-gray-400 text-sm">
                {props.label}
            </small>
            <span className="text-white font-bold text-4xl">
                {props.value}
            </span>
        </div>  
}