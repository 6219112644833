import { NavFooter } from "../Footer/NavFooter";
import { Stepper } from "../Stepper/Stepper";

export function InitialSteps(){
    return <div className="h-full justify-center flex flex-col gap-2">
        <h2 className="text-white font-bold text-3xl">
        ¿What is Battle Hero?
        </h2>
        <span className="text-white">
            Battle Hero is a <strong>Web3</strong> Play-to-Earn game for Android and iOS where players earn cryptocurrency through battles and own in-game assets as <strong>NFTs</strong>.
        </span>        
        <span className="text-white font-bold text-3xl">
            Get started!
        </span>
        <Stepper></Stepper>        
    </div>
}