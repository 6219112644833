import { Button } from "flowbite-react";
import { Link } from "react-router-dom";
import { ItemImage } from "../../Components/ItemImage/ItemImage";
import { HomeItemProps } from "./HomeItemProps";
import { HiArrowLeftCircle } from "react-icons/hi2";


export function WhatCanIWinHere(props: HomeItemProps) {

    return <div className="w-full h-full grid md:grid-cols-2 grid-cols-1 auto-rows-min p-2 gap-2">
        <div className="flex flex-row gap-2">
            <Button color={"dark"} onClick={props.onBack} className="flex justify-start self-start text-white">
                <HiArrowLeftCircle className="text-2xl"/>
                <span className="ml-2">Back</span>
            </Button>
            <h1 className="text-3xl text-left font-bold tracking-tight text-white">
                What can I win here?
            </h1>
        </div>
        <p className="text-base leading-relaxed text-left text-white">
            In BattleHero TG you can win multiple prizes in NFT and token.
        </p>
        <div className="flex flex-col gap-1 items-center">
            <div className="dark bg-slate-800 border-0 self-center justify-self-center flex flex-row items-center gap-2 p-2 rounded-lg w-full h-full">
                <ItemImage width={46} id={76} />
                <div className="flex flex-col items-start">
                    <h2 className="text-gray-400 font-bold text-left">TG Box</h2>
                    <small className="text-gray-400 text-left">
                        Drop tickets redeemable for NFT heroes usable in-game.
                    </small>
                </div>
            </div>        
            <div className="dark bg-slate-800 border-0 self-center justify-self-center flex flex-row items-center gap-2 p-2 rounded-lg w-full h-full">
                <ItemImage width={46} id={78} />
                <div className="flex flex-col items-start">
                    <h2 className="text-gray-400 font-bold text-left">$THUND</h2>
                    <small className="text-gray-400 text-left">
                        One of the bargaining chips for the store.
                    </small>
                </div>
            </div>        
            <div className="dark bg-slate-800 border-0 self-center justify-self-center flex flex-row items-center gap-2 p-2 rounded-lg w-full h-full">
                <ItemImage width={46} id={16} />
                <div className="flex flex-col items-start">
                    <h2 className="text-gray-400 font-bold text-left">$BATH</h2>
                    <small className="text-gray-400 text-left">
                        The main token of the game. <a className="text-blue-400" href="https://poocoin.app/tokens/0x0bc89aa98ad94e6798ec822d0814d934ccd0c0ce">See price</a>
                    </small>
                </div>
            </div>        
            <div className="dark bg-slate-800 border-0 self-center justify-self-center flex flex-row items-center gap-2 p-2 rounded-lg w-full h-full">
                <ItemImage width={46} id={74} />
                <div className="flex flex-col items-start">
                    <h2 className="text-gray-400 font-bold text-left">$BATH Multiplier</h2>
                    <small className="text-gray-400 text-left">
                        Multiply your $BATH earnings temporarily.
                    </small>
                </div>                                
            </div>        
            <div className="dark bg-slate-800 border-0 self-center justify-self-center flex flex-row items-center gap-2 p-2 rounded-lg w-full h-full">
                <ItemImage width={46} id={21} />
                <div className="flex flex-col items-start">
                    <h2 className="text-gray-400 font-bold text-left">NFT Ticket</h2>
                    <small className="text-gray-400 text-left">
                        Tickets redeemable for NFT.
                    </small>
                </div> 
            </div>       
            <div className="dark bg-slate-800 border-0 self-center justify-self-center flex flex-row items-center gap-2 p-2 rounded-lg w-full h-full">
                <ItemImage width={46} id={73} />
                <div className="flex flex-col items-start">
                    <h2 className="text-gray-400 font-bold text-left">
                        Roulette Ticket
                    </h2>
                    <small className="text-gray-400 text-left">
                        Play roulette and win extra prizes.
                    </small>
                </div>                
            </div>        
        </div>
        <p className="text-base leading-relaxed text-left text-white">

        </p>
    </div>;
}
