import { Alert, Button } from "flowbite-react";
import { useState } from "react";
import { HiInformationCircle } from "react-icons/hi2";
import { HiCheckCircle } from "react-icons/hi2";
import googleplay from "./googleplay.png";
import appstore from "./appstore.png";
import { HiArrowLeftCircle } from "react-icons/hi2";
import { LinkForm } from "../Link/LinkForm";

export function Stepper() {
    const [step, setStep] = useState<number | null>(null);
    const [steps, setSteps] = useState<any[]>([{
        label: '1 - Download the game & create account', 
        step: <StepWrapper onBack={() => setStep(null)}><DownloadGameStep onComplete={() => {
            setStep(null);
            // Set this step as donde true
            setSteps(steps.map(e => {
                if(e.label == '1 - Download the game & create account'){
                    return {...e, done: true};
                }
                return e;
            }))                    
        }}></DownloadGameStep></StepWrapper>, 
        done: false
    }, {
        label: '2 - Link TON address to the game', 
        step: <StepWrapper onBack={() => setStep(null)}><LinkForm></LinkForm></StepWrapper>, 
        done: false
    },
    ]);    
    if(step != null){
        return steps[step].step;
    }
    return (
        <>
            <ol className="space-y-4 w-full pl-4 pr-4 flex flex-col h-96 justify-start">
                {steps.map((e, index: number) => {
                    return <li onClick={() => setStep(index)} className="cursor-pointer" key={index}>
                        {e.done ? <SuccesStepItem label={e.label}></SuccesStepItem> : <NonCompletedStepItem label={e.label}></NonCompletedStepItem>}
                </li>
                })}
            </ol>
        </>
    );
}


export interface StepProps{
    label: string
    onBack?: () => void
    onComplete?: () => void
}
export function SuccesStepItem(props: StepProps){
    return  <Alert withBorderAccent icon={HiCheckCircle} color="success">
        <div className="w-full flex justify-between">
            <div>{props.label}</div>
        </div>
    </Alert>
}
export function NonCompletedStepItem(props: StepProps){
    return  <Alert withBorderAccent icon={HiInformationCircle} color="info">
        <div className="w-full flex justify-between">
            <div>{props.label}</div>
        </div>
    </Alert>
}

export function StepWrapper({children, onBack}: {children: React.ReactNode, onBack?: () => void}) {
    return <div className="w-full flex flex-col items-center justify-center p-4 gap-2">
        <Button color={"dark"} onClick={onBack} className="flex justify-start self-start text-white">
            <HiArrowLeftCircle className="text-2xl"></HiArrowLeftCircle>
            <span className="ml-2">Back</span>
        </Button>
        {children}
    </div>
}

interface StepPropsContentProps{
    onComplete?: () => void
}
export function DownloadGameStep(props: StepPropsContentProps){
    return <div className="w-full flex flex-col gap-2 items-center justify-center p-4">
        <iframe className="w-full rounded-lg" width="560" height="215" src="https://www.youtube.com/embed/buQKYD7ftuk?si=Etd5YQOA47tMMGy8" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen>
        </iframe>
        <Alert icon={HiInformationCircle} className="bg-slate-800 text-left text-white">If you don't have the game yet, download it and create an account.</Alert>
        <div className="flex flex-row gap-2 items-center">
            <a className="max-w-[150px]" href="https://play.google.com/store/apps/details?id=com.LionGames.BattleHero.v2&referrer=utm_source%3Dbot_telegram%26utm_medium%3Dbot_telegram"><img className="w-full" src={googleplay}>
                </img>
            </a>
            <a className="max-w-[140px]" href="https://apps.apple.com/es/app/battle-hero/id6503414005">
                <img className="w-full" src={appstore}></img>
            </a>
        </div>
        <Button onClick={props.onComplete} color={"success"} className={"w-full"}>
            I already have it!
        </Button>
    </div>
}