import { Card } from "flowbite-react";
import { useState } from "react";
import { HiCheckBadge } from "react-icons/hi2";
import { LuPanelLeftOpen } from "react-icons/lu";
import { HowDoestItWorks } from "./Home/HowDoestItWorks";
import { WhatCanIWinHere } from "./Home/WhatCanIWinHere";
import { HowToGetBath } from "./Home/HowToGetBath";

export function Home(){
    const [currentRender, setCurrentRender] = useState<any>(null);
    if(currentRender){
        return currentRender;
    }
    return   <div className="items-center justify-center h-full flex flex-col p-2 gap-2">
            <div className="flex flex-col">
                <h1 className="text-3xl font-bold tracking-tight text-white">
                    Welcome to BattleHero
                </h1>
                <small className="text-gray-400 font-bold">
                    Telegram Bot
                </small>
            </div>
            <iframe className="w-full rounded-lg" width="560" height="215" src="https://www.youtube.com/embed/buQKYD7ftuk?si=Etd5YQOA47tMMGy8" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen>
            </iframe>
            <Card onClick={() => setCurrentRender(<HowDoestItWorks onBack={() => setCurrentRender(null)} />) } className="dark bg-slate-800 border-0 w-full cursor-pointer">
                <div className="w-full flex flex-row items-center justify-between">
                    <h2 className="text-gray-400 font-bold text-1xl text-left">
                        How does it work?
                    </h2>
                    <LuPanelLeftOpen size={24} className="text-gray-400"/>
                </div>
            </Card>  
            <Card onClick={() => setCurrentRender(<WhatCanIWinHere onBack={() => setCurrentRender(null)}/>) } className="dark bg-slate-800 border-0 w-full cursor-pointer">
                <div className="w-full flex flex-row items-center justify-between">
                    <h2 className="text-gray-400 font-bold text-1xl text-left">
                        What can I win here?
                    </h2>
                    <LuPanelLeftOpen size={24} className="text-gray-400"/>
                </div>
            </Card>            
            <Card onClick={() => setCurrentRender(<HowToGetBath onBack={() => setCurrentRender(null)}/>) } className="dark bg-slate-800 border-0 w-full cursor-pointer">
                <div className="w-full flex flex-row items-center justify-between">
                    <h2 className="text-gray-400 font-bold text-1xl text-left">
                        How to get $BATH?
                    </h2>
                    <LuPanelLeftOpen size={24} className="text-gray-400"/>
                </div>
            </Card>            
        </div>  
}

