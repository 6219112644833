import { TaskMenuItemProps } from "./TaskMenuItemProps";

export function TaskMeunItem(props: TaskMenuItemProps) {
    return <div onClick={() => props.onClick(props.taskType)} className={`flex flex-col gap-2 items-center ${props.selected ? 'bg-blue-700' : ''} active:bg-blue-700 w-full pt-2 pb-2`}>
         {props.indicator && <span className="absolute -top-1 right-2 flex h-3 w-3">
            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow-400 opacity-75"></span>
            <span className="relative inline-flex rounded-full h-3 w-3 bg-yellow-300"></span>
        </span>}
        <span>{props.text}</span>
        {props.icon}
    </div>;
}
