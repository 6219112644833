import { Button } from "flowbite-react";
import { Link } from "react-router-dom";
import { ItemImage } from "../../Components/ItemImage/ItemImage";
import { HomeItemProps } from "./HomeItemProps";
import { HiArrowLeftCircle } from "react-icons/hi2";

export function HowToGetBath(props: HomeItemProps) {

    return <div className="w-full h-full grid md:grid-cols-2 grid-cols-1 auto-rows-min p-2 gap-2">
        <div className="flex flex-row gap-2">
            <Button color={"dark"} onClick={props.onBack} className="flex justify-start self-start text-white">
                <HiArrowLeftCircle className="text-2xl"/>
                <span className="ml-2">Back</span>
            </Button>
            <h1 className="text-3xl text-left font-bold tracking-tight text-white">
                How to get $BATH?
            </h1>
        </div>
        <p className="text-base leading-relaxed text-left text-white">
            To earn $BATH you have multiple ways.
        </p>
        <div className="flex flex-col gap-1 items-center">
            <div className="dark bg-slate-800 border-0 self-center justify-self-center flex flex-row items-center gap-2 p-4 rounded-lg w-full h-full">                
                <div className="flex flex-col items-start">
                    <h2 className="text-gray-400 font-bold text-left">Playing with NFTs</h2>
                    <small className="text-gray-400 text-left">
                        Get NFTs in monthly Airdrops, purchasing boxes or in our marketplace and play BattleHero with them.
                    </small>
                </div>
            </div>    
            <div className="dark bg-slate-800 border-0 self-center justify-self-center flex flex-row items-center gap-2 p-4 rounded-lg w-full h-full">                
                <div className="flex flex-col items-start">
                    <h2 className="text-gray-400 font-bold text-left">Marketplace</h2>
                    <small className="text-gray-400 text-left">
                        You can sell your NFTs in our marketplace for $BNB. Buy $BATH on Pancake and get shares.
                    </small>
                </div>
            </div>    
            <div className="dark bg-slate-800 border-0 self-center justify-self-center flex flex-row items-center gap-2 p-4 rounded-lg w-full h-full">                
                <div className="flex flex-col items-start">
                    <h2 className="text-gray-400 font-bold text-left">Battle Pass</h2>
                    <small className="text-gray-400 text-left">
                        The crypto battle pass offers certain amounts of $BATH as rewards, to other boxes that can obtain $BATH as well.
                    </small>
                </div>
            </div>                   
        </div>
    </div>;
}
