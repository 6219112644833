import React, { useContext, useState } from "react";
import { ErrorToast } from "../Components/Toasts/ErrorToast";
import { SuccessToast } from "../Components/Toasts/SuccessToast";
import { LoadingToast } from "../Components/Toasts/LoadingToast";
import { InfoToast } from "../Components/Toasts/InfoToast";


interface ToastItem{
    text: string | JSX.Element;
    type: "success" | "error" | "loading" | "info";
}
export interface ToastContextState {
    showToast: (item: ToastItem) => void;
}
export const ToastContext = React.createContext<ToastContextState>({
    showToast: (item: ToastItem) => {},
});
export const ToastProvider: React.FC<{ children: React.ReactNode }> = (
    { children },
) => {
    const [toasts, setToasts] = useState<ToastItem[]>([]);
    const showToast = (item: ToastItem) => {
        setToasts([...toasts, item]);
    };
    const clearToast = () => {        
        setToasts([]);
    }
    const renderToast = (toastItem: ToastItem) => {
        switch (toastItem.type) {
            case "success": 
                return <SuccessToast onDismis={clearToast} text={toastItem.text}></SuccessToast>;
            case "error":
                return <ErrorToast onDismis={clearToast} text={toastItem.text}></ErrorToast>;
            case "loading":
                return <LoadingToast onDismis={clearToast} text={toastItem.text}></LoadingToast>;
            case "info":
                return <InfoToast onDismis={clearToast} text={toastItem.text}></InfoToast>;
        }
    }
    return (
        <ToastContext.Provider value={{showToast}}>
            {toasts.length > 0 && <div className="fixed bottom-20 w-full left-4 z-20 flex flex-col gap-4">
                {toasts.map((e) => {
                    return renderToast(e);
                })}
            </div>}
            {children}
        </ToastContext.Provider>
    );
};

export const useToast = () => useContext(ToastContext);
