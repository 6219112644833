import React, { createContext, useContext, useState, useCallback } from 'react';

// Define the shape of your context
interface AudioContextType {
  playAudio: (id: string) => void;
}

// Create the context with a default value
const AudioContext = createContext<AudioContextType | undefined>(undefined);

// Create the provider component
export const AudioProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);

  const playAudio = useCallback((id: string) => {
    // Stop any current audio
    if (audio) {
      audio.pause();
    }

    // Create a new Audio instance with the provided id (file name)
    const newAudio = new Audio(`./assets/${id}.mp3`);
    newAudio.play();
    
    // Set the audio state to track the current audio being played
    setAudio(newAudio);
  }, [audio]);

  return (
    <AudioContext.Provider value={{ playAudio }}>
      {children}
    </AudioContext.Provider>
  );
};

// Create a custom hook for easier usage of the AudioContext
export const useAudio = () => {
  const context = useContext(AudioContext);
  if (!context) {
    throw new Error('useAudio must be used within an AudioProvider');
  }
  return context;
};
