
export const fetchWrapper = async (input: RequestInfo | URL, init?: RequestInit): Promise<Response> => {
    try {
      var headers = init?.headers ?? {};           
      headers = {
        ...headers,
        'Content-Type': 'application/json',
        'Accept': 'application/json', 
        'X-Token': localStorage.getItem('session') ?? ''
      }
      var finalInit: RequestInit = {};
      if(init?.method == 'GET'){
        finalInit = {
          method: 'GET',
          credentials: 'include', 
          headers: headers,
        };  
      }
      if(init?.method == 'POST' || init?.method == 'DELETE' || init?.method == 'PUT'){
        finalInit = {
          method: init?.method,
          credentials: 'include', 
          headers: headers,
          body: init?.body
        };
      }
      const response = await fetch(input, finalInit);            
      return response;
    } catch (error) {
      console.error('Fetch error:', error);
      throw error;
    }
  };
  