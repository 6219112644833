import { useContext, useState } from "react";
import box from "../Components/Boxes/box.png";
import { ItemImage } from "../Components/ItemImage/ItemImage";
import useInventory from "../Hook/useInventory";
import { ItemNames } from "../Core/Shared/ItemNames";
import { Item } from "../Core/Inventory/Item";
import { fetchWrapper } from "../utils/fetchWrapper";
import { Badge } from "flowbite-react";
import { useToast } from "../Context/ToastContext";
import { groupAndAccumulateItemsByIdAndAttributes } from "../utils/groupAndAccumulateItemsByIdAndAttributes";
import { AccountLinkedContext } from "../Context/AccountLinkedContext";


export function ClaimOnGameToastContent(){
    return <div className="flex flex-col text-left">
        <span>Claim this item on Game</span>
        <div className="flex flex-row w-full gap-2">
            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.LionGames.BattleHero.v2" className="text-blue-500">Android</a>
            <a href="https://apps.apple.com/us/app/battle-hero/id6503414005" className="text-blue-500">iOS</a>
        </div>
    </div>
}
export function ClaimOnDappToastContent(){
    return <div className="flex flex-col text-left">
        <span>Claim this item on DAPP</span>
        <div className="flex flex-row w-full gap-2">
            <a target="_blank" href="https://play.battlehero.io" className="text-blue-500">Dapp</a>
        </div>
    </div>
}

export function Inventory(){
    const [selectedItem, setSelectedItem] = useState<Item | null>(null);
    const { inventory, fetchInventory } = useInventory();
    const {showToast} = useToast();
    const handleSelectItem = (item: Item) => {
        const claimer = claimers.find(e => e.isItem(item.id));
        if(claimer == null){
            showToast({text: <ClaimOnGameToastContent/>, type: "info"});
            return;
        }
        if(claimer instanceof ClaimOnDappItemClaimer){
            showToast({text: <ClaimOnDappToastContent/>, type: "info"});
            return;
        }
        setSelectedItem(item);
    }

    const onClose = () => {
        setSelectedItem(null);
        fetchInventory();
    }

    return <div className="p-2 h-full overflow-auto">  
        <h2 className="text-white text-left text-2xl font-bold mb-2">
            Your inventory
        </h2>
        <div className="grid grid-cols-2 gap-2">
            {inventory?.items.map(e => <InventoryItem item={e} onSelect={handleSelectItem} itemId={e.id} name={
                `x${e.amount} ${ItemNames.Get(e.id).name}`
            } key={e.id}></InventoryItem>)}
        </div>    
        {selectedItem && <ClaimItemDialog onClose={onClose} item={selectedItem}></ClaimItemDialog>}               
    </div>
}

export interface InventoryItemProps{
    itemId: number;
    name: string;
    item: Item;
    onSelect: (item: Item) => void;
}
export function InventoryItem(props: InventoryItemProps){

   
    return <div onClick={() => {
        props.onSelect(props.item);        
    }} className="rounded active:bg-slate-700 cursor-pointer bg-slate-800 p-2 flex flex-col justify-center items-center">
        <ItemImage id={props.itemId} width={44}/>
        <span className="text-white font-bold mt-2">
            {props.name}
        </span>
    </div>
}

export interface InventoryItemClaimer{
    isItem(id: number): boolean;
    claim(id: number): Promise<Item[]>;
}

export class ClaimOnDappItemClaimer implements InventoryItemClaimer{
    isItem(id: number): boolean {
        return [21, 22, 23, 24, 25, 26].includes(id);
    }
    async claim(id: number): Promise<Item[]> {
        return []
    }
}

export class BoxInventoryItemClaimer implements InventoryItemClaimer{
    isItem(id: number): boolean {
        return [76].includes(id);
    }
    async claim(id: number): Promise<Item[]> {
        const rewards = await openBox(id);
        return rewards;        
    }
}
const claimers: InventoryItemClaimer[] = [
    new BoxInventoryItemClaimer(),    
    new ClaimOnDappItemClaimer()
]
export interface ClaimItemDialogProps{
    item: Item;
    onClose: () => void;
} 
export function ClaimItemDialog(props: ClaimItemDialogProps){
    const claimer = claimers.find(e => e.isItem(props.item.id))
    const [rewards, setRewards] = useState<Item[]>([]); 
    const [claiming, setClaiming] = useState<boolean>(false);   
    const { refreshUser } = useContext(AccountLinkedContext);
    const claim = async () => { 
        if(claiming){
            return;
        }            
        setClaiming(true);
        if(rewards.length > 0){
            setClaiming(false);
            props.onClose();
            return;
        }
        if(!claimer){
            setClaiming(false);
            props.onClose();
            return;
        }
        const _rewards: Item[] = await claimer.claim(props.item.id);
        refreshUser();
        setRewards(_rewards);
        setClaiming(false);
    }

    return  <div onClick={claim} className="fixed bottom-0 right-0 bg-black/60 w-full overflow-auto h-full z-50">
                <div className="w-full h-full flex flex-col justify-center items-center cursor-pointer">
                    <div className="animate-bounce w-[50%]">
                        {rewards.length == 0 && <ItemImage id={props.item.id}></ItemImage>}
                    </div>
                    <div className="w-[100%] overflow-auto p-4">
                        {rewards.length > 0 && <div className="grid grid-cols-3 gap-2">
                            {groupAndAccumulateItemsByIdAndAttributes(rewards).map(e => <div className="p-2 bg-slate-800 rounded flex flex-col justify-center items-center">
                                <ItemImage width={64} id={e.id}></ItemImage>
                                <span className="text-white font-bold mt-2">
                                    {ItemNames.Get(e.id).name} x{e.amount}
                                </span>
                            </div>)}
                        </div>}
                    </div>
                    {rewards.length == 0 && claimer != null && <Badge color={"dark"} className="dark text-white text-1xl mb-2">
                        Tap to claim
                    </Badge>}        
                </div>
            </div>
}


const openBox = async (id: number) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/box/open`;
    const response = await fetchWrapper(endpoint, {
        method: "POST",
        body: JSON.stringify({boxId: id})
    }); 
    if(!response.ok){        
        return null;
    }
    const json = await response.json();
    const rewards = json.data.rewards;
    return rewards;
}