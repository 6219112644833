import { useContext, useEffect, useState } from "react";
import { fetchWrapper } from "../utils/fetchWrapper";
import { Badge, Button, Card, Spinner } from "flowbite-react";
import {Address} from '@ton/core';
import tsk from '../Components/Boxes/tsk.png';
import box from '../Components/Boxes/box.png';
import Countdown from "../Components/Countdown";
import { BattleHeroContext } from "../Hook/useBattleHero";
import { AccountLinkedContext } from "../Context/AccountLinkedContext";
export function Airdrop(){

    const [page, setPage] = useState(1);
    const [position, setPosition] = useState(0);
    const [fetching, setFetching] = useState<boolean>(false);
    const [leaderboard, setLeaderboard] = useState<any[]>([]);
    const [airdrop, setAirdrop] = useState<any>(null);
    const [initialSeconds, setInitialSeconds] = useState(0);
    const [end, setEnd] = useState<boolean>(false);
    // const { user } = useContext(BattleHeroContext);
    const { linkedUser } = useContext(AccountLinkedContext);    
    const user = linkedUser;
    const fetchLeaderboard = async () => {
        if(end == true){
            return;
        }
        if(fetching == true){
            return;
        }
        setFetching(true);
        const endpoint = `${process.env.REACT_APP_API_URL}/ton/leaderboard/${page}/10`;        
        const response = await fetchWrapper(endpoint, {
            method: "GET"
        });
        const json = await response.json();        
        const leaderboardItems = json.data.users;    
        if(leaderboardItems.length == 0){
            setEnd(true);
            setFetching(false);
            return;
        }    
        // Append items
        setLeaderboard(prevLeaderboard => [
            ...prevLeaderboard,
            ...leaderboardItems
        ]);
        setFetching(false);
    }

    const fetchCurrentPosition = async () => {        
        const endpoint = `${process.env.REACT_APP_API_URL}/ton/player/leaderboard/`;        
        const response = await fetchWrapper(endpoint, {
            method: "GET"
        });
        const json = await response.json();                 
        setPosition(json.data.position);        
    }

    const fetchAirdrop = async () => {
        const endpoint = `${process.env.REACT_APP_API_URL}/ton/airdrop`;        
        const response = await fetchWrapper(endpoint, {
            method: "GET"
        });
        const json = await response.json();
        setAirdrop(json.data.airdrop);
        const initialSeconds = json.data.airdrop.endsAt - Math.round(Date.now() / 1000);        
        setInitialSeconds(initialSeconds);
    }

    useEffect(() => {
        fetchCurrentPosition();
        fetchAirdrop();
    }, [])

    useEffect(() => {        
        fetchLeaderboard();
    }, [page]);

    const handleScroll = (event: any) => {
        if(fetching){
            return;
        }
        const bottom = event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;            
        if (bottom) {
            setPage(prevPage => prevPage + 1); // Use functional update
        }
    }
    if(!user){
        return <Spinner/>
    }        
    return <div>
        <div className="h-[175px] p-2 gap-2 flex flex-col">
            <h2 className="text-left text-3xl font-bold text-white ml-2">Airdrop</h2>
            <p className="text-white text-sm text-left ml-2">
                Every month, TG box will be airdropped. These boxes offer ticket drops redeemable for NFT to consume in Battle Hero and get $BATH.
                <a target="_blank" className="text-blue-500 ml-2" href="https://whitepaper.battlehero.io/telegram-bot/airdrops">Read more.</a>
            </p>            
            <div className="flex flex-row gap-2">
                <div className="relative flex flex-row items-center">
                    <img className="w-12" src={box}></img>            
                    <span className="absolute bottom-2 right-0 text-xs text-black font-bold rounded-full bg-yellow-300 px-1">
                        ?
                    </span>
                </div>
                <div className="dark bg-slate-800 rounded text-white w-full font-bold pt-1 pb-1"color="dark">
                    <small className="text-white/40">Next airdrop</small>
                    {airdrop && initialSeconds > 0 && <Countdown onFinish={() => {
                        setInitialSeconds(0);
                    }} initialSeconds={initialSeconds}></Countdown>}
                    {airdrop && initialSeconds <= 0 && airdrop.status == "completed" && <p>Airdrop finished!</p>}                    
                    {!airdrop && <p>Airdrop not started yet!</p>}
                </div>
            </div>
        </div>
        <h2 className="text-left text-3xl font-bold text-white ml-2 mt-2 mb-4">Leaderboard</h2>
        <div className={`overflow-hidden bg-sky-800 m-2 p-2 rounded text-white`}>    
                <div className="flex flex-row items-center justify-between gap-2">
                    <div className="flex flex-row gap-4 items-center">
                        <h2 className="text-2xl flex items-center justify-center font-bold rounded-full bg-gray-700 w-10 h-10">
                            {position}                     
                        </h2>
                        <span>{user.address.substring(0, 6) + "..." + user.address.substring(38, 42)}</span>
                    </div>
                    <div className="flex flex-row gap-2 items-center">
                        <span>{Math.round(user.balance.tskPoints)}</span>
                        <img className="h-6 w-6" src={tsk}></img>                
                    </div>
                </div>                
            </div>
        <div onScroll={handleScroll} className="h-[calc(100vh-425px)] rounded overflow-y-scroll">
        <div className="flex flex-col gap-2 p-2">
            {leaderboard.map((participant: any, index) => {                
                const bg = participant.id == user.id ? 'bg-sky-800' : 'bg-gray-800'; 
                return <div key={index} className={`overflow-hidden p-2 ${bg} rounded text-white`}>    
                    <div className="flex flex-row items-center justify-between gap-2">
                        <div className="flex flex-row gap-4 items-center">
                            <h2 className="text-2xl flex items-center justify-center font-bold rounded-full bg-gray-700 w-10 h-10">{index+1}</h2>
                            <span>{participant.address.substring(0, 6) + "..." + participant.address.substring(38, 42)}</span>
                        </div>
                        <div className="flex flex-row gap-2 items-center">
                            <span>{Math.round(participant.balance.tskPoints)}</span>
                            <img className="h-6 w-6" src={tsk}></img>                
                        </div>
                    </div>                
                </div>
            })}
            {fetching == true && <div className="w-full flex flex-col items-center overflow-hidden p-2 justify-center rounded text-white">
                <Spinner size={"lg"}></Spinner>
            </div>}
        </div>
        </div>
    </div>
}