import { useTonAddress } from "@tonconnect/ui-react";
import { Clipboard, Table } from "flowbite-react"
import { ItemImage } from "../Components/ItemImage/ItemImage";
import { useEffect, useState } from "react";
import { fetchReferralCounter } from "../utils/api";
import { MdOutlineIncompleteCircle } from "react-icons/md";
import { RiVerifiedBadgeFill } from "react-icons/ri";

export function Referral() {
    const rawAddress = useTonAddress(false);
    const [referralCounter, setReferralCounter] = useState(0);

    const _fetchReferralCounter = async () => {
        const counter = await fetchReferralCounter();
        setReferralCounter(counter);
    }

    useEffect(() => {
        _fetchReferralCounter();
    }, [])

    return <div className="w-full h-full overflow-auto flex flex-col items-start justify-center p-4">
        <h1 className="text-white font-bold text-2xl text-left">
            Referral
        </h1>
        <p className="text-white text-left">
            Earn more <strong>TSK</strong> by inviting your friends.
        </p>
        <div className="w-full dark">
            <Table color="dark" className="w-full dark">
                <Table.Head>
                    <Table.HeadCell></Table.HeadCell>
                    <Table.HeadCell>Prize</Table.HeadCell>
                    <Table.HeadCell>Amount</Table.HeadCell>
                    <Table.HeadCell>Referrals</Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y">
                    <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                        <Table.Cell>
                            {referralCounter >= 1 ? <RiVerifiedBadgeFill className="text-green-500 dark:text-green-500" /> : <MdOutlineIncompleteCircle className="text-yellow-400 dark:text-yellow-400" />}
                        </Table.Cell>
                        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                            <ItemImage id={79} width={22} height={22}></ItemImage>
                        </Table.Cell>
                        <Table.Cell>100</Table.Cell>
                        <Table.Cell>x1</Table.Cell>
                    </Table.Row>
                    <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                    <Table.Cell>
                        {referralCounter >= 5 ? <RiVerifiedBadgeFill className="text-green-500 dark:text-green-500" /> : <MdOutlineIncompleteCircle className="text-yellow-400 dark:text-yellow-400" />}
                    </Table.Cell>
                        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                            <ItemImage id={79} width={22} height={22}></ItemImage>
                        </Table.Cell>
                        <Table.Cell>1000</Table.Cell>
                        <Table.Cell>x5</Table.Cell>
                    </Table.Row>
                    <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                    <Table.Cell>
                        {referralCounter >= 10 ? <RiVerifiedBadgeFill className="text-green-500 dark:text-green-500" /> : <MdOutlineIncompleteCircle className="text-yellow-400 dark:text-yellow-400" />}
                    </Table.Cell>
                        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                            <ItemImage id={79} width={22} height={22}></ItemImage>
                        </Table.Cell>
                        <Table.Cell>3000</Table.Cell>
                        <Table.Cell>x10</Table.Cell>
                    </Table.Row>
                    <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                    <Table.Cell>
                        {referralCounter >= 10 ? <RiVerifiedBadgeFill className="text-green-500 dark:text-green-500" /> : <MdOutlineIncompleteCircle className="text-yellow-400 dark:text-yellow-400" />}
                    </Table.Cell>
                        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                            <ItemImage id={79} width={22} height={22}></ItemImage>
                        </Table.Cell>
                        <Table.Cell>250</Table.Cell>
                        <Table.Cell>{`>10`}</Table.Cell>
                    </Table.Row>
                    <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                    <Table.Cell>
                        {referralCounter >= 50 ? <RiVerifiedBadgeFill className="text-green-500 dark:text-green-500" /> : <MdOutlineIncompleteCircle className="text-yellow-400 dark:text-yellow-400" />}
                    </Table.Cell>
                        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                            <ItemImage id={16} width={22} height={22}></ItemImage>
                        </Table.Cell>
                        <Table.Cell>3000 (IG)</Table.Cell>
                        <Table.Cell>x50</Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
            <div className="w-full flex items-start justify-start mt-1">
                <a href="" className="text-blue-500 text-left font-bold">Learn more</a>
            </div>
        </div>
        <h1 className="text-white font-bold text-2xl text-left mt-2 mb-2">
            Share your link
        </h1>
        <div className="grid w-full max-w-[23rem] grid-cols-8 gap-2">
            <label htmlFor="referral" className="sr-only">
                Label
            </label>
            <input id="referral" type="text"
                className="col-span-6 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-500 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-gray-400 dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                value={`${process.env.REACT_APP_REGISTER_URL}?referral=${rawAddress}`}
                disabled
                readOnly
            />
            <Clipboard color="dark" className="dark bg-slate-800" valueToCopy={`${process.env.REACT_APP_REGISTER_URL}?referral=${rawAddress}`} label="Copy" />
        </div>
    </div>
}